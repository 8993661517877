.title_sub_wrp label {white-space: pre-wrap;}
.title_sub_wrp textarea {height: 58px !important;border: 1px solid #ccc !important;border-radius: 5px;resize: none;padding: 10px;}
.title_sub_wrp .MuiFormGroup-root {width: fit-content;}
.interview_wrp .heading h1 {font-size: 25px;}
@media only screen and (max-width: 1399px) {
.interview_wrp .heading h1 {font-size: 20px;line-height: 25px;margin: 5px 0;word-break: break-word;}

}
@media only screen and (max-width: 399px) {
    .interview_wrp .heading h1 {font-size: 18px;line-height: 22px;}
}

