.landing_page3_bannerblock .lpage_banner_titlewrapper h1{font-size:63px;line-height:63px}
.landing_page3_bannerblock .lpage_banner_sub_wrapper{position: relative; background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_banner_bg2.jpg);background-repeat:no-repeat;background-size:cover;padding:25px;width:910px}
.landing_page3_bannerblock .lpage_banner_sub_wrapper::after{content:'';width:93%;height:8px;position:absolute;left:50%;transform:translateX(-50%);top:-4px;background-color:#00f6ff}
.landing_page3_bannerblock .lpage_banner_textwrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_banner_bg3.png);background-repeat:no-repeat;background-size:100% 100%;padding:20px;width:auto}
.landing_page3_bannerblock .lpage_banner_textwrapper h4{font-weight:var(--main-font-Bold);font-size:42px;line-height:45px;color:#000;text-shadow: 1px 1px 1px rgba(8,231,252,1);}
.landing_page3_bannerblock .lpage_banner_sub_wrapper h2{color:#fff;font-size:38px;line-height:43px;font-weight:var(--main-font-Medium);margin-bottom:20px}
.landing_page3_bannerblock .form_style .formfild input{padding:8px 14px}
.landing_page3_bannerblock .css-ahj2mt-MuiTypography-root{font-size:20px;font-family:"Poppins",sans-serif}
.landing_page3_bannerblock .form_text p{margin-top:22px;font-size:28px;line-height:28px;text-shadow: none;}
.landing_page3_bannerblock .form_style .MuiFormLabel-root{top:-8px;font-weight:var(--main-font-Medium)}
.landing_page3_bannerblock .formwrapper{background:rgb(36,36,36);background:linear-gradient(180deg,rgba(36,36,36,1) 0%,rgba(23,23,23,1) 100%)}
.landing_page3_bannerblock .landing-banner-right{background-color:rgba(255,255,255,.3);padding:10px;border-radius:10px}
.landing_page3_bannerblock{padding:50px 0}
.landing_page3_bannerblock {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_bannerimage.webp), 
    url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_banner_bg.jpg);
    background-repeat: no-repeat;
    background-size: auto, cover;
    background-position: right 400px top, center center;
}
.landing_page3_bannerblock .form_title_wrapper{background:rgb(8,231,252);background:linear-gradient(180deg,rgba(8,231,252,1) 0%,rgba(39,134,234,1) 100%)}
.landing_page3_bannerblock .form_title_wrapper h2 span{background:#011c31;-webkit-background-clip:text;-webkit-text-fill-color:transparent;font-size:28px;line-height:30px; filter: drop-shadow(1px 1px 0px #08e6fc);}
.landing_page3_bannerblock .form_style .formfild input{background-color:#3f4e55;color:#fff}
.landing_page3_bannerblock .form_style .MuiOutlinedInput-notchedOutline{border:1px solid #557381!important}
.landing_page3_bannerblock .form_style .MuiFormLabel-root{color: #fff !important;}
.landing_page3_bannerblock .submitbtnsection button{background:rgb(255,138,6);background:linear-gradient(180deg,rgba(255,138,6,1) 0%,rgba(255,22,1,1) 100%);border:1px solid #ffcc52}
.landing_page3_bannerblock .landing-col-container{align-items: flex-start;}
.landing_page3_bannerblock .lpage_arrow_wrapper h2{font-size:42px;line-height:43px;font-weight:var(--main-font-SemiBold)}
.landing_page3_bannerblock .lpage_arrow_wrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_arrowbg_blue.png);padding:50px 60px 50px 40px}

.textwrapper h2{font-size:46px;color:#000;font-weight:var(--main-font-Bold);margin-bottom:15px}
.textwrapper p{font-size:25px;color:#000;font-weight:var(--main-font-Medium);margin-bottom:20px}
.textwrapper p span{color: #0052e8;}
.landing_page3_listwrapper h2{font-size:36px;color:#0052e8;font-weight:var(--main-font-SemiBold);margin-bottom:15px}
.landing_page3_list p{font-size:20px;color:#000;font-weight:var(--main-font-Medium);position:relative;padding-left:32px}
.landing_page3_list p::after{content:'';height:11px !important;width:11px !important;background-color:#fff !important;border:4px solid #03d6d5;border-radius:50%;position:absolute;left:0;top:8px;box-shadow:0 4px 2px rgba(0,0,0,.3)}
.landing_page3_list p span{color: #0052e8;}
.landing_page3_block2 .landing_page3_list{column-count:2}
.landing_page3_block2{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block2_image1.webp),url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block2_bg.png);background-repeat:no-repeat;padding:25px 0;background-position:left 20px top 30px,right bottom;margin-top:-3%;background-color:#fff;background-size:auto,cover;}
.landing_page3_block2_subwrapper{padding-left:710px}
.landing_page3_block3_subwrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block3_bg1.jpg);background-repeat:no-repeat;padding:35px;border-radius:15px;background-size:cover}
.landing_page3_block3_colcontainer{
    background: rgba(188,188,188,0.2);
    padding:50px 0;border-radius:15px;display:flex;justify-content:space-between;background-size:cover;padding-bottom:75px;position:relative}
.landing_page3_block3_colcontainer::after{content:'';position:absolute;left:33%;top:50%;transform:translateY(-50%);height:95%;width:2px;background:rgb(253,154,38);background:linear-gradient(180deg,rgba(253,154,38,0) 0%,rgba(200,203,205,1) 50%,rgba(253,121,34,0) 100%)}
.landing_page3_block3_colcontainer::before{content:'';position:absolute;right:33%;top:50%;transform:translateY(-50%);height:95%;width:2px;background:rgb(253,154,38);background:linear-gradient(180deg,rgba(253,154,38,0) 0%,rgba(200,203,205,1) 50%,rgba(253,121,34,0) 100%)}
.landing_page3_block3_colcontainer .block3-col{background-repeat:no-repeat;padding:15px;margin:0 50px;border-radius:8px;width:calc(33.33% - 130px);position:relative;border:1px solid #fff;background-size:cover}
.landing_page3_block3_colcontainer .block3-col{background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block3_bg3.jpg);}
/* .landing_page3_block3_colcontainer .block3-col:nth-child(2){background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block3_bg4.jpg);} */
/* .landing_page3_block3_colcontainer .block3-col:nth-child(3){background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block3_bg5.jpg);} */
.colnumber{font-size:44px;font-weight:var(--main-font-Bold);color:#fff;height:77px;width:77px;background-color:#041c52;border:12px solid #d5dadc;border-radius:50%;display:flex;justify-content:center;align-items:center;box-shadow:0 0 8px rgba(0,0,0,.3);position:absolute;left:50%;top:-36px;transform:translateX(-50%)}
.colicon{font-size:44px;font-weight:var(--main-font-Bold);color:#fff;height:124px;width:124px;background-color:#041c52;border:12px solid #d5dadc;border-radius:50%;display:flex;justify-content:center;align-items:center;box-shadow:0 0 8px rgba(0,0,0,.3);position:absolute;left:50%;bottom:-53px;transform:translateX(-50%)}
.block3-col-text{border:1px solid rgba(255,255,255,.3);border-radius:8px;padding:45px 10px;padding-bottom:72px;text-align:center;height:100%}
.block3-col-text h3{color:#fff;font-size:20px;font-weight:var(--main-font-SemiBold);margin-bottom:25px;text-shadow:0 0 5px rgba(0,0,0,.493)}
.block3-col-text p{color:#fff;font-size:20px;font-weight:var(--main-font-SemiBold);text-shadow:0 0 5px rgba(0,0,0,.493)}

.title_wrapper h1{font-size:46px;color:#000;font-weight:var(--main-font-Bold);margin-bottom:10px}
.landing_page3_block4_colcontainer{display:flex;flex-wrap:wrap;margin-left:-4px;margin-bottom:16px}
.block4_col{width:calc(25% - 8px);margin:4px;background:rgb(18,187,233);background:linear-gradient(150deg,rgba(18,187,233,1) 0%,rgba(18,187,233,1) 70%,rgba(33,192,236,1) 70%,rgba(33,192,236,1) 80%,rgba(51,199,238,1) 80%,rgba(63,207,242,1) 100%);padding:12px;text-align:center;display:flex;flex-direction:column;justify-content:center}
.block4_col h4{color:#000;font-size:20px;line-height:23px;text-transform:uppercase;font-weight:var(--main-font-SemiBold)}
.block4_col h2{color:rgb(255,255,255);font-size:33px;line-height:35px;text-transform:uppercase;font-weight:var(--main-font-Bold);text-shadow:0 0 5px rgb(0 0 0 / 49%)}
.block4_col p{color:#000;font-size:20px;font-weight:var(--main-font-SemiBold)}
.landing_page3_block4_sub_textwrapper h3{color:#0b58a0;font-size:33px;line-height:35px;font-weight:var(--main-font-SemiBold);margin-bottom:15px}
.landing_page3_block4_sub_textwrapper h1{color:#0052e8;font-size:48px;line-height:50px;font-weight:var(--main-font-Bold);margin-bottom:15px}
.landing_page3_block4_sub_textwrapper p{color:#000;font-size:23px;font-weight:var(--main-font-Medium);position:relative;padding-left:33px;display:inline-block;margin-right:20px}
.landing_page3_block4_sub_textwrapper p::after{content:'';height:16px;width:16px;background-color:#03aae8;border:1px solid #fff;border-radius:50%;position:absolute;left:0;top:8px}
.landing_page3_block4_sub_textwrapper{background:rgb(209,239,244);background:linear-gradient(90deg,rgba(209,239,244,1) 0%,rgba(209,239,244,0) 100%);padding:22px}
.landing_page3_block4{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block4_bg1.webp);background-repeat:no-repeat;background-position:right top -120px;padding:45px 0 20px 0}
.landing_page3_block4_subwrapper{padding-right:770px}

.landing_page3_block5_subwrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block5_bg1.jpg);background-repeat:no-repeat;background-size:cover;padding:45px;padding-left:650px;position:relative;margin-bottom: 35px;}
.landing_page3_block5_subwrapper .abimage{position:absolute;left:180px;bottom:0}
.landing_page3_block5_colcontainer{display:flex}
.block5-col{width:calc(33.33% - 40px);margin:0 20px}
.block5-col-subwrapper{padding:11px;background-color:rgba(255,255,255,.3);height:100%}
.block5-col-subwrapper1{padding:7px;background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block5_bg3.jpg);background-repeat:no-repeat;background-size:cover;height:100%}
.block5-col-subwrapper2{background-color:#00102f;background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block5_bg2.png);background-repeat:no-repeat;background-position:center bottom;background-size:100% auto;padding:15px 6px;padding-bottom:25px;height:100%;position:relative}
.borderdiv{width:94%;height:2px;background:rgb(106,108,111);background:linear-gradient(90deg,rgba(106,108,111,0) 0%,rgba(106,108,111,1) 50%,rgba(106,108,111,0) 100%);position:absolute;top:52%;left:50%;transform:translateX(-50%)}
.block5-col-subwrapper2 h2 span{font-size:25px;font-weight:var(--main-font-Bold);background: linear-gradient(180deg, rgba(11,221,250,1) 0%, rgba(31,157,239,1) 100%);;-webkit-background-clip:text;-webkit-text-fill-color:transparent;display:block;text-align:center;line-height:28px}
.block5-col-subwrapper2 p{color:#fff;font-size:20px;line-height:25px;font-weight:var(--main-font-Medium);text-align:center;min-height:140px;padding:20px 19px;padding-bottom:10px}
.block5-col-subwrapper2 p span{color: #009afe;}

.landing_page3_block5-1_block_wrapper{background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/Landing_page3_block5.1_BG_image1.png);background-repeat: no-repeat;background-size: 100%;background-position: top center;padding: 10px;margin-bottom: 30px;
}
.LP3_b5-1_top_box_headder{background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/Landing_page3_block5.1_top_headder_BG.png) ,linear-gradient(180deg, rgba(1,56,119,1) 0%, rgba(1,34,69,1) 100%);background-repeat: no-repeat;background-blend-mode: color-dodge , normal;background-size: 100% 100% , 100%;border-radius: 5px;border: 1px solid #fff;box-shadow: 0 0 5px #00000075;}
.LP3_b5-1_top_box_headder h1{color: #fff;font-size: 54px;font-weight: 700;text-align: center;text-transform: uppercase;text-shadow: 0 0 6px #000000b8;}
.LP3_b5-1_top_box_headder span{color: #009afe;background: linear-gradient(180deg, rgba(9,227,251,1) 17%, rgba(39,134,234,1) 100%);-webkit-background-clip: text; -webkit-text-fill-color: transparent;text-shadow: none;}
.LP3_b5-1_top_box_lower_color1 p{font-size: 21px;font-weight: 500;padding-left: 16px;}
.LP3_b5-1_top_box_lower_color1{display: flex;padding-right: 55px;align-items: center;}
.LP3_b5-1_top_box_lower{display: flex;margin: 20px 0;}
.LP3_b5-1_tb_lower_colorbox1{background-color: #0cc36a;border: 2px solid #08893e;}
.LP3_b5-1_tb_lower_colorbox2{background-color: #83adc8;border: 2px solid #668da6;}
.LP3_b5-1_tb_lower_colorbox3{background-color: #ffcf84;border: 2px solid #c19c64;}
.LP_b5-1_size{height: 50px;width: 110px;border-radius: 3px;}

/* table start */
/* .LP3_b5-1_center_box{border: 5px solid #b1cbdb; border-radius: 10px;}
.LP3_b5-1_table_row_wrapper{display: flex;justify-content: space-between;}
.LP3_b5-1_center_box_headding_wrapper .LP3_b5-1_table_row_wrapper p{background-color: #013b7f;color: #fff;font-size: 27px;font-weight: 700;line-height: 32px;display: table;}
.LP3_b5-1_center_box_describtion_wrapper .LP3_b5-1_table_row_wrapper p{background-color: #b1cbdb;font-size: 16px;font-weight: 500;}
.LP3_b5-1_table_row_wrapper p span{display: table-row;height: 100%;text-align: center;width: 100%;}
.LP3_b5-1_table_row_wrapper p span label{display: table-cell;padding: 10px;vertical-align: middle;}
.LP3_b5-1_table_row_wrapper p:not(:last-child){border-right: 2px solid #fff;}
.LP3_b5-1_table_row_wrapper p:not(:last-child){    width: 16%;}
.LP3_b5-1_table_row_wrapper:nth-of-type(2n) p span label{background-color: #fff;}
.LP3_b5-1_center_box_describtion_wrapper .LP3_b5-1_table_row_wrapper:not(:last-child) p{border-bottom: 2px solid #fff;}
.LP3_b5-1_table_row_wrapper p:nth-of-type(2){width: 10%;}
.LP3_b5-1_table_row_wrapper p{    align-items: center;display: table;justify-content: center;padding: 4px;}
.LP3_b5-1_table_row_wrapper p:first-of-type{padding: 0;width: 8%;}

.LP3_b5-1_center_box_describtion_wrapper .LP3_b5-1_table_row_wrapper :not(:first-child) p:first-child{padding: 0 .2% 0 0;width: 7.8%;}
.LP3_b5-1_center_box_describtion_wrapper .LP3_b5-1_table_row_wrapper p:first-child span label{background: #fff;}
.LP3_b5-1_center_box_describtion_wrapper .LP3_b5-1_table_row_wrapper p:first-child span{font-size: 20px;line-height: 26px;}
.LP3_b5-1_center_box_describtion_wrapper .LP3_b5-1_table_row_wrapper :not(:last-child) p:first-child{border-bottom: 2px solid #c7e1d3;} */

.expns_rdctn_wrap {
    padding: 30px 8px 12px;
    background-color: #ffffff7d;
    box-shadow: -1px 1px 6px 0px rgb(0 0 0 / 18%);
}
.table_main_wrapper {
    margin-top: 35px;
    border: 5px solid #b1cbdb;
    border-radius: 10px;
}

.expns_rdctn_wrap>h2 {
    font-size: 48px;
    font-weight: bold;
    color: #042241;
    text-transform: uppercase;
}

.expns_rdctn_wrap>h4 {
    font-size: 34px;
    line-height: 36px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    margin-top: 20px;
    padding: 18px 18px;
    background: linear-gradient(180deg, rgba(44, 177, 235, 1) 33%, rgba(1, 139, 200, 1) 72%);
    border-image: linear-gradient(#37617b, #37617b) 30;
    border-style: solid;
    border-width: 1px;
}

.color_indicator {
    display: flex;
    margin-top: 25px;
}

.color_indicator p {
    font-size: 21px;
    font-weight: 500;
    color: #000000;
    padding-left: 130px;
    position: relative;
}

.color_indicator p::after {
    content: '';
    position: absolute;
    height: 50px;
    width: 108px;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    border-radius: 3px;
}

.color_indicator p:nth-child(1) {
    width: 24%;
}

.color_indicator p:nth-child(2) {
    width: 19%;
}

.color_indicator p:nth-child(3) {
    width: 14%;
}

.color_indicator p:nth-child(1)::after {
    background-color: #abf55b;
    border: 2px solid #11b767;
}

.color_indicator p:nth-child(2)::after {
    background-color: #83adc8;
    border: 2px solid #668da6;
}

.color_indicator p:nth-child(3)::after {
    background-color: #ffcf84;
    border: 2px solid #c19c64;
}

.color_indicator p:not(:last-child) {
    margin-right: 65px;
}

.table_heading_row_wrapper .table_row_wrapper p {
    background-color: #013b7f;
    color: #fff;
    font-size: 27px;
    font-weight: bold;
    line-height: 32px;
}

.table_desc_row_wrapper .table_row_wrapper p {
    font-size: 16px;
}

.table_desc_row_wrapper .table_row_wrapper p:first-child span {
    font-size: 20px;
    line-height: 26px;
}

.table_desc_row_wrapper .table_row_wrapper p {
    background-color: #b1cbdb;
}

.table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p span label {
    background-color: #fff;
}

.table_row_wrapper {
    display: flex;
    justify-content: space-between;
}

.table_row_wrapper p {
    padding: 4px;
    display: table;
    justify-content: center;
    align-items: center;
}

.table_row_wrapper p span {
    display: table-row;
    width: 100%;
    height: 100%;
    text-align: center;
}

.table_row_wrapper p span label {
    padding: 10px;
    display: table-cell;
    vertical-align: middle;
}

.table_row_wrapper p:nth-of-type(1) {
    width: 7.8%;
    padding: 0;
}

.table_row_wrapper p:nth-of-type(2) {
    width: 14%;
}

.table_row_wrapper p:nth-of-type(3) {
    width: 19%;
}

.table_row_wrapper p:nth-of-type(4) {
    width: 8%;
}

.table_row_wrapper p:nth-of-type(5) {
    width: 14%;
}

.table_row_wrapper p:nth-of-type(6) {
    width: 14%;
}

.table_row_wrapper p:nth-of-type(7) {
    width: 16%;
}

.table_row_wrapper p:nth-of-type(8) {
    width: 10%;
}

.table_row_wrapper p:not(:last-child) {
    border-right: 2px solid #fff;
}

.table_desc_row_wrapper .table_row_wrapper:first-child p:first-child span label {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.table_desc_row_wrapper .table_row_wrapper:last-child p:first-child span label {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.table_desc_row_wrapper .table_row_wrapper p:first-child span label {
    background: #fff;
}

.table_desc_row_wrapper .table_row_wrapper:first-child p:first-child {
    padding: 4px 0.2% 0 0;
    width: 7.8%;
}

.table_desc_row_wrapper .table_row_wrapper:not(:first-child) p:first-child {
    padding: 0 0.2% 0 0;
    width: 7.8%;
}

.table_desc_row_wrapper .table_row_wrapper:not(:last-child) p {
    border-bottom: 2px solid #fff;
}

.table_desc_row_wrapper .table_row_wrapper:not(:last-child) p:first-child {
    border-bottom: 2px solid #c7e1d3;
}

.table_heading_row_wrapper .table_row_wrapper:first-child p:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 3px;
}

.table_heading_row_wrapper .table_row_wrapper:first-child p:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 3px;
}

.table_common_font p {
    color: #000;
    font-weight: 500;
}

.green_bg_color {
    background-color: #aaf55b !important;
}

.orange_bg_color {
    background-color: #ffcf84 !important;
}

.darkr_grey_bgcolor {
    background-color: #83adc8 !important;
}

.LP3_b5-1_bottom_box h1{font-size: 34px;font-weight: 700;color: #009afe;background: linear-gradient(180deg, rgba(9,227,251,1) 17%, rgba(39,134,234,1) 100%);-webkit-background-clip: text; -webkit-text-fill-color: transparent;text-shadow: none;text-align: center;}
.LP3_b5-1_bottom_box{background: linear-gradient(180deg, rgba(1,56,119,1) 0%, rgba(1,34,69,1) 100%);border: 1px solid #fff;padding: 15px;margin-top: 25px;box-shadow: 0 0 7px #00000061;}

/* responsive table*/


@media only screen and (max-width: 1758px) {
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 18px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 1622px) {
    .table_heading_row_wrapper .table_row_wrapper p {
        font-size: 23px;
        line-height: 28px;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 15px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 16px;
        line-height: 20px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 32px;
        line-height: 34px;
    }
}

@media only screen and (max-width: 1480px) {
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 13px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 14px;
        line-height: 18px;
    }
    .table_heading_row_wrapper .table_row_wrapper p {
        font-size: 21px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 1345px) {
    .table_heading_row_wrapper .table_row_wrapper p {
        font-size: 19px;
        line-height: 24px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 12px;
        line-height: 16px;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1245px) {
    .table_heading_row_wrapper .table_row_wrapper p {
        font-size: 18px;
        line-height: 23px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 11px;
        line-height: 15px;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 11px;
    }
}

@media only screen and (max-width: 1199px) {
    .table_heading_row_wrapper {
        display: none;
    }
    .table_main_wrapper {
        border: none;
        margin-top: 10px;
    }
    .table_common_font span:before {
        content: attr(data-label);
        background: #013b7f;
        width: 49%;
        padding: 6px;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        display: table-cell;
        vertical-align: middle;
    }
    .table_row_wrapper p span {
        text-align: left;
        display: table-row;
    }
    .table_common_font span label {
        display: table-cell;
        width: 49%;
        padding: 6px!important;
        vertical-align: middle;
    }
    .table_row_wrapper {
        display: block;
        margin-top: 10px;
    }
    .table_row_wrapper:first-of-type {
        margin-top: 0px;
    }
    .table_desc_row_wrapper .table_row_wrapper:not(:first-child) p:first-child {
        padding: 0;
    }
    .table_desc_row_wrapper .table_row_wrapper:not(:last-child) p {
        border-bottom: 1px solid #e1e1e1;
    }
    .table_row_wrapper p {
        width: 100% !important;
        padding: 0;
        display: table;
    }
    .table_desc_row_wrapper .table_row_wrapper:first-child p:first-child span label {
        border-radius: 0;
    }
    .table_desc_row_wrapper .table_row_wrapper:first-child p:first-child {
        padding: 0;
    }
    .table_row_wrapper p:not(:last-child) {
        border: 0
    }
    .table_desc_row_wrapper .table_row_wrapper:not(:last-child) p:first-child {
        border-bottom: 1px solid #b1cbdb;
    }
    .table_desc_row_wrapper .table_row_wrapper:last-child p:first-child {
        border-bottom: 1px solid #e1e1e1;
    }
    .table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p span label {
        background-color: #b1cbdb;
    }
    .table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p:first-child span label {
        background-color: #fff;
    }
    .table_desc_row_wrapper .table_row_wrapper:last-child p:first-child span label {
        border-radius: 0;
    }
    .table_desc_row_wrapper .table_row_wrapper:last-child p:not(:first-child) {
        border-bottom: 1px solid #e1e1e1;
    }
    .expns_rdctn_wrap>h2 {
        font-size: 35px;
        text-align: center;
    }
    .expns_rdctn_wrap {
        padding: 12px 8px 12px;
    }
    .color_indicator {
        display: block;
        margin-top: 0;
    }
    .color_indicator p {
        width: auto!important;
        margin-right: 0!important;
        text-align: center;
        padding-left: 0;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .color_indicator p::after {
        content: '';
        position: inherit;
        height: 20px;
        width: 50px;
        transform: inherit;
        top: 0;
        left: 0;
        border-radius: 3px;
        display: inline;
        margin: 0 0 0 20px;
        margin-top: 0;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 16px;
        line-height: 22px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 16px;
        line-height: 22px;
    }
    .table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p span label {
        background-color: #fff;
    }
    .table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p:not(:first-child) {
        border-bottom: 1px solid #b1cbdb;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 26px;
        line-height: 28px;
    }
    .LP3_b5-1_top_box_lower{flex-direction: column;}
    .LP3_b5-1_top_box_lower_color1{justify-content: center; padding: 0;     margin: 10px 0;}

    .LP_b5-1_size {
        height: 40px;
        width: 40px;
        border-radius: 3px;
    }

}

@media only screen and (max-width: 792px) {
    .expns_rdctn_wrap>h2 {
        font-size: 32px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 24px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 736px) {
    .expns_rdctn_wrap>h4 {
        font-size: 27px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 640px) {
    .expns_rdctn_wrap>h2 {
        font-size: 26px;
    }
    .color_indicator p {
        font-size: 16px;
        padding: 5px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 22px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 520px) {
    .expns_rdctn_wrap>h2 {
        font-size: 22px;
    }
    .color_indicator p {
        font-size: 13px;
        padding: 3px;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 14px;
        line-height: 18px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 14px;
        line-height: 18px;
    }
    .table_common_font label:before {
        font-size: 16px;
    }
    .table_common_font span:before {
        font-size: 16px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 20px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 440px) {
    .expns_rdctn_wrap>h2 {
        font-size: 19px;
        line-height: 24px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 18px;
        line-height: 20px;
        padding: 10px 10px;
    }
}

@media only screen and (max-width: 384px) {
    .color_indicator p {
        flex-direction: column;
    }
    .color_indicator p::after {
        margin: 5px 0 0 0;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 14px;
        line-height: 17px;
    }
}


/* responsive table */

/* teble end */


.landing_page3_listwrapper p{color:#000;font-size:23px;font-weight:var(--main-font-Medium);position:relative;padding-left:33px;display:inline-block;margin-right:20px}
.landing_page3_listwrapper p::after{content:'';height:16px;width:16px;background-color:#03aae8;border-radius:50%;position:absolute;left:0;top:8px}
.textwrapper h4{color:#0b58a0;font-size:30px;line-height:38px;font-weight:var(--main-font-SemiBold);margin-bottom:15px}
.landing_page3_block6 .textwrapper h2{line-height:59px;margin-bottom:5px;color:#073170}
.landing_page3_block6_subwrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block6_bg1.jpg);background-repeat:no-repeat;background-size:cover;padding:40px 540px 30px 30px;position:relative}
.landing_page3_block6_subwrapper .abimage{position:absolute;right:40px;bottom:0;width: 510px;}
.landing_page3_block6_subwrapper .textwrapper h4{padding-right:80px}




.landing_page3_block7_colcontainer{display:flex;flex-wrap:wrap;margin:0 -30px;margin-bottom:30px}
.block7-col{width:calc(33.33% - 60px);margin:0 30px;background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block7_bg1.png);background-repeat:no-repeat;background-size:cover;background-position:center top;background-color:#012b59;border-radius:40px;padding:26px;text-align:center;position:relative;padding-bottom:100px}
.block7-col h3{color:#fff;font-size:25px;font-weight:var(--main-font-Bold);margin-bottom:15px}
.block7-col h4{color:#000;font-size:25px;font-weight:var(--main-font-Bold);background: linear-gradient(190deg, rgba(8,231,252,1) 0%, rgba(37,137,235,1) 100%);padding:9px;position:absolute;left:50%;bottom:0;transform:translateX(-50%);width:80%;border-radius:70px 70px 0 0}
.block7-col p{margin-top:15px;color:#fff;font-size:18px;font-weight:var(--main-font-SemiBold)}
.landing_page3_block7_list{background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage/images/landing_page3_block7_list_img2layer.png) ,linear-gradient(175deg, rgba(7,44,87,1) 5%, rgba(12,66,126,1) 100%);background-position: right;background-repeat: no-repeat;background-blend-mode: darken;background-size: 30% 100% , cover;margin:18px 0;display:inline-block;width:calc(100% - 00px);padding:15px 10px;position:relative;padding-left:150px;margin-bottom: 9px;}
.landing_page3_block7_list h4{color:#fff;font-size:66px;font-weight:var(--main-font-SemiBold);height:86px;width:86px;display:flex;justify-content:center;align-items:center;background:#009afe;    background: linear-gradient(190deg, rgba(8,231,252,1) 0%, rgba(37,137,235,1) 100%);position:absolute;left:20px;bottom:20px;box-shadow:0 0 8px rgba(0,0,0,.3);outline:1px solid rgba(255,255,255,0.4);text-shadow: 0 0 8px #0000007a;}
.landing_page3_block7_list p{color:#fff;font-size:20px;font-weight:var(--main-font-SemiBold)}
.landing_page3_block7_subwrapper .textwrapper{position:relative;padding-left:28px}
.landing_page3_block7_subwrapper .textwrapper::after{content:'';position:absolute;left:0;top:50%;transform:translateY(-50%);width:8px;height:88%;background:#000;background: linear-gradient(180deg,rgba(0,0,0,1) 0%,rgba(0,0,0,1) 80%,rgba(0,171,243,1) 80%,rgba(0,171,243,1) 100%);}
.landing_page3_block7_subwrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block7_bg1.jpg);background-repeat:no-repeat;background-size:cover;padding:40px 40px 25px;border-radius:8px;border:1px solid #cfd1d1;margin-bottom: 35px;}
/* .landing_page3_block7_subwrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block7_bg1.jpg);background-repeat:no-repeat;background-size:cover;padding:40px 40px 25px;border-radius:8px;border:1px solid #cfd1d1} */
.landing_page3_block7 .textwrapper h4{color: #009cff;}


.landing_page3_block8_subwrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block8_image1.webp),url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block8_bg1.jpg);background-repeat:no-repeat;background-size:auto 100%,cover;padding:30px 40px 25px 840px;border-radius:0;border:1px solid #cfd1d1;background-position:left top,right top; }
.textwrapper h3{font-size:40px;line-height:45px;color:#009afe;font-weight:var(--main-font-Bold);margin-bottom:10px}
.landing_page3_block8_subwrapper .textwrapper h2{color:#073170}
.pborder{height:2px;width:100%;background:#53aaad;background:linear-gradient(90deg,rgba(83,170,173,1) 0%,rgba(83,170,173,0) 100%);margin:30px 0}
.landing_page3_block8 .textwrapper p span{color: #009cff;}
.demoimagewrapper img{display: block;width: 100%;}


.landing_page3_block10_subwrapper{padding: 35px; background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block_10_background2new.webp') , url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block_10_background.webp');background-repeat: no-repeat;background-size: 60% , 100% 100%;background-position: bottom left -29%, center;border: 1px solid #cfd1d1;}
.landing_page3_B10_h1{ background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block_10_H1background.png') , linear-gradient(180deg, rgba(1,56,119,1) 0%, rgba(1,33,67,1) 100%);     background-blend-mode: color-dodge; background-repeat: no-repeat;  border-radius: 10px;border: 2px solid #e2f3f3;box-shadow: 0px 0px 11px -6px #000;}
.landing_page3_B10_h1 h1{color: #fff; text-transform: uppercase;text-align: center;     padding: 4px 15px; font-size: 54px;font-weight: 700;filter: drop-shadow(0px 2px 2px rgb(0, 0, 0))}
.landing_page3_block10_textwrapper p{font-size: 25px;font-weight: 600;line-height: 33.02px; margin-top: 30px;}
.landing_page3_block10_textwrapper p span{color: #009afe;}
.landing_page3_block10_textwrapper p strong{color: #0b57a5 ;font-weight: var(--main-font-SemiBold);}
.landing_page3_block10_textwrapper{width: 53%;margin-left: 47%;}


.landing_page3_block12_background{background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/block_12_backgroun_img1_white.png'); padding: 11px;  background-size: 100% 100%; background-repeat: no-repeat;display: flex;justify-content: space-between; align-items: center;}
.LP3_block12_h1_gradient h1{    background: rgb(11,223,251);background: linear-gradient(180deg, rgba(11,223,251,1) 0%, rgba(27,177,243,1) 100%); color: transparent; background-clip: text;font-size: 69px;font-weight: 700;    line-height: 69px; filter: drop-shadow(0px 2px 2px rgb(0, 0, 0));    padding-left: 40px;}
.LP3_block12_h1_gradient{width: 38%; }
.landing_page3_block12_background p{color: #ffffff;font-size: 23px;font-weight: 600;line-height: 30.48px;}
.LP3_block12_text_wrapper{width: calc(64% - 730px);}
.LP3_block12_text_wrapper p span{color: #04e0ee;}
.LP3_block12_sub_add1 img{height: 100%; width: 27%; position: absolute; left: -66px;top: 7px;}
.LP3_block12_sub_add2{background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_block12_add_BG-orange001.png'); background-repeat: no-repeat;    background-size: 100% 100%;width: 100%;    background-position: right top 9px;padding: 10px 151px;padding-bottom: 26px;}
.LP3_block12_add{display: flex;    width: 730px;position: relative;}
.LP3_block12_sub_add2_top_left h1{font-size: 50px; font-weight: 700;color: #041d4c;text-shadow: 0px 2px #ffffffb5;}
.LP3_block12_sub_add2_top_left h2{font-size: 30px; font-weight: 700;color: #041d4c;text-align: end;line-height: 0;text-shadow: 0px 1px 0px #c1fafe;}
.LP3_block12_sub_add2_top{display: flex;}
.LP3_block12_sub_add2_top_right h1{ font-size: 135px;font-weight: 700;color: #041d4c;line-height: 142px;text-shadow: 0px 2px #ffffffb5;}
.LP3_block12_sub_add2 h3{color: #041d4c ;font-size: 34px;font-weight: 700;text-shadow: 0px 2px #ffffffb5;  line-height: 36px;}
.landingThree .state svg {
    color: #ffffff;
}
.landing_page3_block13_text_wrapper{width: 64%;}
.landing_page3_block13_text_wrapper h1 {font-size: 54px;font-weight: 700;}
.landing_page3_block13_text_wrapper_and_bg {padding: 20px;padding-right: 132px;}
.landing_page3_block13_text_wrapper  p{font-size: 23px; font-weight: 400;line-height: 27.94px;padding-top: 10px;  }
.landing_page3_block13 span{color: #009afe;}
.landing_page3_block13_text_wrapper h2{font-size: 36px;font-weight: 600;color: #009cff; padding: 10px 0;}
.landing_page3_block13_text_wrapper li{font-size: 23px;font-weight: 400;padding: 10px 0px; list-style: none;line-height: 25.40px; position: relative;}
.landing_page3_block13_text_wrapper li strong{ font-weight: 600;}
.landing_page3_block13_text_wrapper li::before{content: '';height: 10px;width: 10px; background-color: white;border: 4px solid #02d6d5;border-radius: 50%;left: -30px;top: 13px; position: absolute;box-shadow: 0px 3px 4px rgb(0 0 0 / 62%); }
.landing_page3_block13_text_wrapper ul{padding-left: 27px;width: 89%;}
.landing_page3_block13_text_wrapper button{padding: 25px 80px; font-size: 41px; font-weight: 700;border-radius: 10px;    background: linear-gradient(180deg, rgba(8,213,244,1) 0%, rgba(6,118,204,1) 100%); color: #fff;border-style: none; margin-top: 25px;text-shadow: 0px 0px 8px #0000006b;}
.landing_page3_block13  {background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block13_img1.png');background-repeat: no-repeat; background-size: 49%;background-position: bottom 48% right;}

.landing_page3_block14_text{margin-left:40%;width: 63%;}
.landing_page3_block14_wrapper{padding: 20px;}
.landing_page3_block14_text h1{font-size: 54px;font-weight: 700;}
.landing_page3_block14_text span{color: #009afe;}
.landing_page3_block14_text li strong{font-weight: 600;}
.landing_page3_block14_text li{font-size: 23px;font-weight: 400;padding: 10px 0px; list-style: none;line-height: 25.40px; position: relative;}
.landing_page3_block14_text p{font-size: 23px; font-weight: 400;line-height: 27.94px;padding-top: 10px;}
.landing_page3_block14_text h2{font-size: 36px;font-weight: 600;color: #0b57a5; padding: 15px 0;padding-bottom: 0px;}
.landing_page3_block14{background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block14_img1new.webp');background-repeat: no-repeat;background-size: 55%;background-position: left -113px bottom 36px;}
.landing_page3_block14_text li::before{content: '';height: 10px;width: 10px; background-color: white;border: 4px solid #02d6d5;border-radius: 50%;left: -30px;top: 13px; position: absolute;box-shadow: 0px 3px 4px rgb(0 0 0 / 62%); }
.landing_page3_block14_sub_wrapper ul{ padding-left: 5%;width: 99%;}
.landing_page3_block14_sub_wrapper{padding-left: 5%;width: 96%;}
.landing_page3_block14_sub_wrapper p{width: 85%;}




.landing_page3_block15_wrapper{background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/Landing_page3_block15_bg.png');background-repeat: no-repeat;background-size: cover;border-radius: 10px;position: relative;    padding-top: 30px;padding-right: 30%;padding-left: 30px;padding-bottom: 30px;}
.landing_page3_block15_wrapper p{font-size: 25px;font-weight:500;color: #fff;line-height: 35.56px;text-shadow: 0px 0px 5px #000000bf;padding: 11px 145px 10px 0px;}

.landing_page3_block15_wrapper h1{font-size: 54px;font-weight: 700;color: #fff;background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/Landing_page3_block15_blue_tag_middle.png');background-repeat: no-repeat;padding-left: 42px;line-height: 80px;width: auto;margin-left: -48px;
    background-size: 100% 100%;    padding-right: 20px;    display: inline-block; text-shadow: 0px 0px 8px #00000052;position: relative;}
.landing_page3_block15_wrapper h2{font-size: 42px; font-weight: 700;line-height: 43.18px; background: linear-gradient(180deg, rgba(254,238,2,1) 40%, rgba(253,184,9,1) 60%);color: transparent;  -webkit-background-clip: text; background-clip: text;filter: drop-shadow(0px 6px 5px rgba(0, 0, 0, 0.439));padding: 0 20px 0 0;}
.landing_page3_block15_wrapper img{position:absolute;bottom: 0;right: 30px;width: 30%;}
.h1_wrapper h1::after{content: ''; background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/Landing_page3_block15_blue_tag_before.png');background-repeat: no-repeat;height: inherit;width: 100%;    background-size: 100% 100%;height: 131%;width: 30.05%;position: absolute;right: -30%;top: 34%;transform: translateY(-50%);}
.h1_wrapper h1::before{content: '';background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/Landing_page3_block15_Orange_tag_after.png');background-repeat: no-repeat; width: 100%;    background-size: 100% 100%;height: 12px;width: 18px;position: absolute;left: 0%;top: -7%;transform: translateY(-50%);z-index: -1;}
.h1_wrapper{width: 73%;}



.text_wrapper_b16{padding-left: 38%;}
.LP3_b16_top h1{font-size: 54px;font-weight: 700;line-height: 74.94px;}
.LP3_b16_top p{font-size: 23px;font-weight: 400;line-height: 30.48px;padding: 25px 0;padding-top: 10px;}
.landing_page3_block16 span{color: #009afe;}
.LP3_b16_bottom h2{color: #0b57a5;font-weight: 600;font-size: 36px;line-height:37.94px;}
.LP3_b16_bottom h3{font-size: 27px;font-weight: 700;line-height: 35.56px;padding: 10px 0;}
.LP3_b16_list li{font-size: 23px;font-weight: 400;line-height: 45.72px;list-style: none;position: relative;}
.LP3_b16_list {padding-left: 37px;}
.LP3_b16_list li::before{content: '';height: 10px;width: 10px; background-color: white;border: 4px solid #02d6d5;border-radius: 50%;left: -30px;top: 13px; position: absolute;box-shadow: 0px 3px 4px rgb(0 0 0 / 62%); }
.LP3_b16_list ul{column-count: 2;column-gap: 35px;}
.landing_page3_block16{background-image: url('https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block16_bg.webp');background-repeat: no-repeat;background-size: 100%;    background-position: left bottom 58%;padding: 30px 0;}


.landing_page3_damotable{ width: 100%;}




.landing_page3_block9{margin-bottom:40px}
.audit_back_wrap{border-radius:8px;padding:0;box-shadow:0 0 3px 3px rgba(0,0,0,0.2);padding:35px;background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block008_image1.webp),url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/l_page3_block8_bg.jpg);background-repeat:no-repeat;background-size:auto,cover;background-position:right bottom,center center}
.audit_text_box2{padding-right:120px}
.audit_analyze_header{text-align:center;background:linear-gradient(#123775,#0a2650);margin-bottom:20px}
.audit_analyze_header h1{color: #fff; text-transform:uppercase;font-size: 54px;font-weight: 700;}
.audit_text_wrap{width:60%}
.audit_text_box{margin-top:15px}
.audit_text_wrap h4{font-size:24px;font-weight:600}
.audit_text_wrap h3{font-size:35px;font-weight:600;color:#1f57a2;margin-bottom: 7px;line-height: 45px;}
.audit_text_wrap p{font-size:25px;font-weight:500;line-height:30px}
.audit_text_wrap h2{font-size:36px;color:#123573}
.audit_font{color:#009afe}
.audit_footer{margin-top:15px;margin-bottom:20px;display:flex;flex-direction:column}
.audit_footer_list{display:flex;margin-top:10px}
.audit_footer_list_right{margin-left:50px}
.audit_footer_list ul{font-size:23px;list-style:none;font-weight:600;list-style-position:inside}
.audit_footer_list ul li{position:relative;padding-left:30px}
.audit_footer_list ul li::before{content:'';position:absolute;width:15px;height:15px;border-radius:50%;background:#009afe;top:8px;left:0;border:1px solid #fff}
.fa-circle{color:#009afe;font-size:15px;margin-right:20px}


.LP3_b17_bgimg{background-image: url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/Landing_page3_block17_bg.png);background-repeat: no-repeat; background-size: cover;    border-radius: 10px;}
.LP3_b17_text_wrapper h1::before {content: ''; background: linear-gradient(180deg, rgba(7,232,198,1) 20%, rgba(108,130,244,1) 80%);width: 7px;height: 80%; left: -23px;top: 9px; position: absolute;}
.LP3_b17_text_wrapper h1{position: relative;color: #fff;font-size: 33px;font-weight: 600;line-height: 43.18px;}
.LP3_b17_text_wrapper span{background: linear-gradient(190deg, rgba(19,217,249,1) 0%, rgba(24,172,227,1) 100%);color: transparent;-webkit-background-clip: text;background-clip: text;}
.landing_page3_block17 .LP3_b17_text_wrapper{padding: 20px 55px;}
.landing_page3_block17{    padding: 0 0 20px 0;}


.LP3_b11_gradient_bg{background: linear-gradient(180deg, rgba(240,254,252,1) 0%, rgba(187,196,199,1) 100%);padding: 15px;border: 1px solid #fff;box-shadow: 0px 0px 6px #00000069;}
.LP3_b11_ingBG{background-image:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_b11_ingBG1.png);background-repeat: no-repeat;background-size: 100% 100%;position: relative;z-index: 0;}
.LP3_b11_ingBG .bgimg2{position: absolute;right: -27px;bottom: 1px;z-index: -1;}
.LP3_b11_padding_wrapper{padding: 25px;display: flex;}
.LP3_b11_gradient_box{background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_b11_gradient_box_img_logo.png) , url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_b11_gradient_box_img.png) , linear-gradient(180deg, rgba(22,187,254,1) 0%, rgba(0,94,196,1) 100%);    background-size: 30% ,100% 100%,100%;background-blend-mode: normal , soft-light , normal;background-repeat: no-repeat;background-position: top -22px right 4px , center , center;margin-right: 25px;
    border-radius: 15px;padding: 13px;width: calc(23% - 47px);border: 1px solid #37e1fb;}
.LP3_b11_border_box {border: 1px solid #ffffff63;border-radius: 15px;display: flex;flex-direction: column;align-items: center;text-align: center;padding: 16px;height: 100%;position: relative;}
#demoIMG{width: 100%;}
.LP3_b11_border_box h1{font-size: 32px;font-weight: 700;color: #fff;background: linear-gradient(90deg, rgba(85,206,254,0) 0%, rgba(85,206,254,1) 40%, rgba(85,206,254,1) 50%, rgba(85,206,254,1) 60%, rgba(85,206,254,0) 100%);margin: 10px 0;text-shadow: 0 0 5px #00000036;}
.LP3_b11_border_box p{font-size: 22px;font-weight: 600;color: #fff;min-height: 105px;line-height: 25px;padding: 0 3px;text-shadow: 0 0 5px #0000003d;}
.LP3_b11_border_box .line_gradiyent{height: 1px;width: 100%;background: linear-gradient(90deg, rgba(85,206,254,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 60%, rgba(85,206,254,0) 100%);position: absolute;top: 68%;left: 50%;transform: translate(-50%);}
.LP3_b11_Icon{width: 100%;margin: 10px 0;}
.landing_page3_block11 {
    margin: 50px 0;
}

.GB_colors1 {background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_b11_gradient_box_img_logo.png) , url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_b11_gradient_box_img.png) , linear-gradient(180deg, rgba(23,153,254,1) 0%, rgba(63,50,196,1) 100%);background-size: 30% ,100% 100%,100%;background-repeat: no-repeat;background-position: top -22px right 4px , center , center;}
.GB_colors2 {background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_b11_gradient_box_img_logo.png) , url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_b11_gradient_box_img.png) , linear-gradient(180deg, rgba(0,204,202,1) 0%, rgba(0,144,132,1) 100%);background-size: 30% ,100% 100%,100%;background-repeat: no-repeat;background-position: top -22px right 4px , center , center;}
.GB_colors3 {background:url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_b11_gradient_box_img_logo.png) , url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/LP3_b11_gradient_box_img.png) , linear-gradient(180deg, rgba(1,205,182,1) 0%, rgba(0,180,106,1) 100%);background-size: 30% ,100% 100%,100%;background-repeat: no-repeat;background-position: top -22px right 4px , center , center;}
.GB_colors2 h1{background: linear-gradient(90deg, rgba(85,206,254,0) 0%, rgba(5,215,207,1) 40%, rgba(5,215,207,1) 50%, rgba(5,215,207,1) 60%, rgba(85,206,254,0) 100%);width: 100%;    margin-top: 15px;}
.GB_colors3 h1{background: linear-gradient(90deg, rgba(85,206,254,0) 0%, rgba(1,232,201,1) 40%, rgba(1,232,201,1) 50%, rgba(1,232,201,1) 60%, rgba(85,206,254,0) 100%);width: 100%;margin-top: 27px;}
.GB_colors1 h1{width: 100%;margin-top: -2px;}
.GB_colors0 h1{width: 100%;margin-top: 13px;}
.GB_paragraph1{margin-top: 10px;}
.GB_paragraph2{margin-top: 10px;}
.GB_paragraph3{margin-top: 10px;}
.GB_paragraph4{margin-top: 10px;}
.GB_colors3 p{padding: 0 7px;}
.border_box_color{border: 1px solid #068fe5;}



.lpage_banner_main_video_wrapper{
        width: 727px;
}
.lpage_banner_sub_video{
      position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.lpage_banner_sub_video iframe{
 position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media only screen and (max-width: 1820px) {
    .landing_page3_block8_subwrapper{    background-position: left -72px top,right top;}
    .landing_page3_block4{    background-position: right -121px top -120px;}
    .landing_page3_block14_wrapper {
       margin-top: -25px;
    }
}



@media only screen and (max-width: 1420px) {
.audit_back_wrap{background-position:right -120px bottom,center center}
.audit_analyze_header h1{font-size:38px}
}
@media only screen and (max-width: 1199px) {
.audit_text_wrap{width:auto}
.audit_text_box2{padding-right:0}
.audit_back_wrap{background-position:center bottom,center center;padding-bottom:47%;background-size:60%,cover}
.audit_analyze_header h1{font-size:38px;line-height:44px}
.audit_analyze_header{padding:10px}
.landing_page3_block9{margin-bottom:20px}
.landing_page3_block14_sub_wrapper{padding-left: 0;}
.LP_b5-1_size {
    height: 40px;
    width: 40px;
    border-radius: 3px;
}


.LP3_b5-1_top_box_headder h1 { 
    font-size: 30px;
    line-height: 32px;
    padding: 10px; 
}
.LP3_b5-1_bottom_box h1{font-size: 28px;
    line-height: 32px;  }
}
@media only screen and (max-width: 767px) {
.audit_back_wrap{padding:20px;padding-bottom:47%}
}
@media only screen and (max-width: 660px) {
.audit_footer_list_right{margin-left:0}
.audit_footer_list{display:block;margin-top:10px}
.audit_text_wrap h2,.audit_text_wrap h3{font-size:32px}
}












 @media only screen and (max-width: 1840.98px) {
.textwrapper h2{font-size:38px;line-height:47px;}
.landing_page3_block2{background-position:left -28px top, right bottom}
.landing_page3_block2_subwrapper{padding-left:35%}
.landing_page3_block5_subwrapper{padding-left: 0;}
.landing_page3_block5_colcontainer{display:flex;width:1090px;margin:0 0 0 auto}
.landing_page3_block5_subwrapper .abimage{    left: 70px;}
.LP3_block12_h1_gradient h1{font-size: 60px;line-height: 60px;}
.LP3_block12_text_wrapper{    width: calc(68% - 730px);}
.landing_page3_block12_background p{    font-size: 20px;line-height: 27px;}
.landing_page3_block15_wrapper p{font-size: 23px;line-height: 32.56px;}
.landing_page3_block15_wrapper h2{font-size: 40px;}
.landing_page3_block15_wrapper h1{font-size: 50px;}
.landing_page3_block10_subwrapper{background-size: 65% , 100% 100%;
    background-position: bottom left -38%, center;}

 }
 @media only screen and (max-width: 1740.98px) {
.landing_page3_block2{background-position:left -103px top, right bottom;padding-bottom:140px;margin-bottom:-110px}
.landing_page3_block2_subwrapper{padding-left: 32%;}
 }
 @media only screen and (max-width: 1720.98px) {
.landing_page3_bannerblock{background-position: right 310px top, center center;}
.landing_page3_block5_colcontainer{width:900px}
.block5-col{width:calc(33.33% - 20px);margin:0 10px}
.block5-col-subwrapper2 h2 span{font-size:20px;line-height:24px}
.block5-col-subwrapper2 p{font-size:18px;line-height:22px;padding:20px 11px;padding-bottom:10px}
.LP3_b16_top h1{font-size: 50px;}
.landing_page3_block10_subwrapper {
    background-size: 68% , 100% 100%;
    background-position: bottom left -47%, center;
}
.LP3_b11_border_box p{font-size: 20px;}

 }
 @media only screen and (max-width: 1700.98px) {
.landing_page3_bannerblock .form_title_wrapper h2 span{font-size:25px;line-height:28px}
.landing_page3_bannerblock .form_title_wrapper{padding: 15px 12px;}
.landing_page3_bannerblock .lpage_banner_titlewrapper h1{font-size:52px;line-height:53px}
.landing_page3_bannerblock .lpage_banner_sub_wrapper h2{font-size:35px;line-height:42px}
.landing_page3_bannerblock .lpage_banner_textwrapper h4{font-size: 37px;}
.LP3_block12_h1_gradient h1{    font-size: 53px;line-height: 53px;}
.landing_page3_block12_background p{font-size: 18px;line-height: 24px;}
.LP3_block12_sub_add2_top_right h1{    font-size: 120px;line-height: 120px;}
.LP3_block12_sub_add2_top_left h2{font-size: 25px;}
.LP3_block12_sub_add2_top_left h1{    font-size: 35px;    padding-top: 0px;}
.LP3_block12_sub_add2 h3{font-size: 28px;}
.LP3_block12_text_wrapper{width: calc(72% - 730px);}
.landing_page3_block15_wrapper h2{font-size: 37px;}
.landing_page3_block15_wrapper p{font-size: 22px;line-height: 27.56px;}
.landing_page3_block15_wrapper h1{font-size: 46px;}
.landing_page3_block13_text_wrapper h1{font-size: 50px;}

 }
 @media only screen and (max-width: 1672.98px){
    .landing_page3_block2{background-position: left -160px top, right bottom;}
    
 }
 @media only screen and (max-width: 1666.98px) {
.landing_page3_block3_colcontainer .block3-col{margin:0 30px;border-radius:8px;width:calc(33.33% - 60px)}
.landing_page3_block3_colcontainer::before{right: 33.3%;}
.landing_page3_block3_colcontainer::after{left: 33.3%;}
.landing_page3_block4{background-position: right -130px top -120px;}
.landing_page3_block4_subwrapper{padding-right:640px}
.pborder{margin: 20px 0;}
.audit_back_wrap{background-position: right -60px bottom,center center;}
 }
 @media only screen and (max-width: 1660.98px) {

.landing_page3_block2_subwrapper{padding-left:30%}
.LP3_b17_text_wrapper h1{font-size: 29px;}
.landing_page3_block10_subwrapper {
    background-size: 73% , 100% 100%;
    background-position: bottom left -71%, center;
}
 }
 @media only screen and (max-width: 1640.98px) {
.landing_page3_bannerblock{background-position: right 260px top, center center;}
.landing_page3_block6 .textwrapper h2{line-height: 45px;font-size: 33px;}
.landing_page3_block6_subwrapper .abimage{width: 441px;}
.landing_page3_block6_subwrapper .textwrapper h4{font-size:26px;line-height:31px}
 }
 @media only screen and (max-width: 1605.98px){
    .landing_page3_block2{background-position: left -160px bottom 64px, right bottom;}
 }
 @media only screen and (max-width: 1600.98px) {
.block4_col h2{font-size:24px;line-height:27px}
.block4_col h4, .block4_col p{font-size:16px;line-height:19px;}
.landing_page3_block4_sub_textwrapper p{font-size: 20px;}
.landing_page3_block4_sub_textwrapper h1{font-size:36px;line-height:40px}
.landing_page3_block4_sub_textwrapper h3{font-size:28px;line-height:30px;}
.title_wrapper h1{font-size:38px;}
.landing_page3_block13_text_wrapper li{font-size: 20px;}
.landing_page3_block13_text_wrapper p{font-size: 20px;}
.landing_page3_block13_text_wrapper h1{font-size: 46px;}
.landing_page3_block13_text_wrapper h2{font-size: 32px;}
.landing_page3_block13_text_wrapper button{font-size: 37px;}
.landing_page3_block14_text h1{font-size: 46px;}
.landing_page3_block14_text p{font-size: 20px;}
.landing_page3_block14_text li{font-size: 20px;}
.landing_page3_block14_text h2{font-size: 32px;}
.landing_page3_block15_wrapper h2{font-size: 34px;line-height: 36.18px;}
.LP3_b16_top h1{font-size: 46px;line-height: 51.94px;}
.LP3_b16_top p{font-size: 21px;    padding: 10px 0;}
.LP3_b16_list li{font-size: 21px;}
.LP3_b16_bottom h3{font-size: 24px;}
.LP3_b16_bottom h2{font-size: 33px;}
.landing_page3_block14{    background-size: 57%;background-position: left -110px center;}
.LP3_b11_border_box p{font-size: 18px;}
.LP3_b11_border_box h1{font-size: 28px;}
 }
 @media only screen and (max-width: 1560.98px) {
.landing_page3_bannerblock .lpage_arrow_wrapper h2{font-size:36px;line-height:40px}
.landing_page3_block2_subwrapper{padding-left:0;width:890px;margin:0 0 0 auto}
.landing_page3_list p{font-size: 16px;line-height: 35px;}
.landing_page3_block2{background-position:left -133px top,right bottom}
.LP3_block12_h1_gradient h1{font-size: 46px;line-height: 46px;}
.landing_page3_block12_background p{font-size: 20px;}
.LP3_block12_text_wrapper{width: calc(76% - 660px);}
.LP3_block12_sub_add2_top_right h1{font-size: 110px;line-height: 110px;}
.LP3_block12_sub_add2_top_left h1{    font-size: 35px;}
.LP3_block12_sub_add2_top_left h2{font-size: 22px;}
.LP3_block12_sub_add2 h3{    font-size: 25px;}
.LP3_block12_sub_add1 img{left: -50px;}
.LP3_block12_add{width: 660px;}
.LP3_block12_sub_add2{padding: 10px 120px;}
.landing_page3_block15_wrapper p{padding: 11px 32px 10px 0px;}
.textwrapper p{line-height: 32px;margin-bottom: 10px;} 
.LP3_b11_padding_wrapper{flex-wrap: wrap;width: 63%;}
.LP3_b11_gradient_box{flex: 1 0 31%;display: flex;} 
.GB_colors2{margin-top: 25px;}
.GB_colors3{margin-top: 25px;}
.LP3_b11_ingBG .bgimg2{width: 54%;bottom: 3px;right: -39px;}
 }
 @media only screen and (max-width: 1540.98px) {
.landing_page3_bannerblock .lpage_banner_sub_wrapper{width:760px}
.landing_page3_bannerblock .lpage_banner_textwrapper h4{font-size:29px;line-height:35px}
.landing_page3_bannerblock .lpage_banner_sub_wrapper h2{font-size:30px;line-height:42px}
.landing_page3_bannerblock .form_text p{margin-top:15px;font-size:21px;line-height:18px}
.landing_page3_bannerblock .css-ahj2mt-MuiTypography-root{    font-size: 17px;}
.landing_page3_bannerblock .css-vqmohf-MuiButtonBase-root-MuiRadio-root{padding: 5px;}
.landing_page3_bannerblock .form_radio{padding-left: 8px;}
.landing_page3_bannerblock .lpage_arrow_wrapper h2{font-size:28px;line-height:33px}
.landing_page3_bannerblock .lpage_arrow_wrapper{padding: 40px 43px 40px 22px;}
.landing_page3_block5_subwrapper .abimage{left:20px}
.landing_page3_listwrapper p::after{top: 5px;}
.landing_page3_listwrapper p{font-size: 18px;}
.landing_page3_block6_subwrapper .textwrapper h4{padding-right:0;font-size:24px}
.landing_page3_block6_subwrapper{    padding: 25px 30px 30px 30px;}
.landing_page3_block6 .textwrapper p, .landing_page3_block6 .textwrapper h4, .landing_page3_block6 .landing_page3_listwrapper{
    padding-right: 500px;
}
.landing_page3_block10_textwrapper p{margin-top: 18px;font-size: 23px;}
.lpage_banner_main_video_wrapper {
    width: 654px;
}
 }
 @media only screen and (max-width: 1520.98px) {
.landing_page3_block4_subwrapper{padding-right:0;width:1000px}
.landing_page3_block4{background-position:right -430px top -120px}
.landing_page3_block8_subwrapper{    background-position: left -110px top,right top;}

}
@media only screen and (max-width: 1500.98px) {
    .audit_text_wrap {
    width: 53%;
}
}
@media only screen and (max-width: 1480.98px) {
.landing_page3_block2{background-position:left -167px top,right bottom}
.landing_page3_block5_colcontainer{width:auto;flex-wrap:wrap;padding-left:520px}
.block5-col-subwrapper2{padding:15px 6px}
.block5-col-subwrapper2 p{min-height:inherit;padding:7px 11px}
.block5-col{width:auto;margin:1%;flex:1 1 48%}
.block5-col-subwrapper2 h2 span{font-size:20px;line-height:25px;display:inline}
.block5-col-subwrapper2 h2{text-align:center}
.block5-col-subwrapper2 p{min-height:inherit}
.borderdiv{display:none}
}
@media only screen and (max-width: 1470.98px) {

.landing_page3_block13_text_wrapper h1{font-size: 40px;}
.landing_page3_block13_text_wrapper li{font-size: 18px; line-height: 25px;padding: 8px 0px;}
.landing_page3_block13_text_wrapper button{font-size: 32px;}
.landing_page3_block13_text_wrapper p{font-size: 18px; line-height: 25px;}
.landing_page3_block13_text_wrapper h2{font-size: 29px;}
.landing_page3_block13_text_wrapper li::before{top: 10px;}
.landing_page3_block14_text li{font-size: 18px; line-height: 25px;padding: 8px 0px;}
.landing_page3_block14_text p{font-size: 18px; line-height: 25px;}
.landing_page3_block14_text h1{font-size: 40px;}
.landing_page3_block14_text h2{font-size: 29px;}
.landing_page3_block14_text li::before{top: 10px;}
.landing_page3_block15_wrapper h1{    font-size: 40px;line-height: 66px;}
.landing_page3_block15_wrapper h2{font-size: 30px;line-height: 34.18px;}
.landing_page3_block15_wrapper p{    font-size: 20px;line-height: 23.56px;}
.LP3_b16_top h1{font-size: 41px;}
.LP3_b16_list li{line-height: 35.72px;}
.LP3_b16_list li::before{    top: 9px;}
.LP3_b17_text_wrapper h1{font-size: 26px;}
}
@media only screen and (max-width: 1440.98px) {
.landing_page3_bannerblock{background-position:right 220px top,center center}
.block7-col{width:calc(33.33% - 20px);margin:0 10px;}
.landing_page3_block7_colcontainer{margin: 0 -10px;}
.landing_page3_block8_subwrapper{background-position:left -320px top,right top;padding:30px 40px 25px 540px}
.landing_page3_block10_subwrapper {
    background-size: 75% , 100% 100%;
    background-position: bottom left -82%, center;
} 
}
 @media only screen and (max-width: 1400.98px) {
.landing_page3_bannerblock .form_title_wrapper h2 span{font-size:20px;line-height:22px}
.landing_page3_bannerblock .lpage_banner_titlewrapper h1{font-size:43px;line-height:44px}
.landing_page3_bannerblock .lpage_banner_sub_wrapper h2{font-size:26px;line-height:40px}
.landing_page3_bannerblock .landing-col-container{align-items:stretch}
.landing_page3_bannerblock .landing-banner-left{display:flex;flex-direction:column;justify-content:space-between}
.landing_page3_block4_subwrapper{padding-right:0;width:800px}
.landing_page3_block6_subwrapper .abimage{right: 20px;}
.landing_page3_block10_textwrapper p{    font-size: 20px;line-height: 29.02px;}
.landing_page3_B10_h1 h1{font-size: 48px;}
.LP3_block12_add{    width: 510px;}
.LP3_block12_sub_add2{padding: 13px 98px;}
.LP3_block12_sub_add1 img{    left: -42px;}
.LP3_block12_sub_add2_top_left h1{font-size: 28px; padding-top: 0;}
.LP3_block12_sub_add2_top_left h2{font-size: 15px;}
.LP3_block12_sub_add2_top_right h1{font-size: 90px;    line-height: 90px;}
.LP3_block12_sub_add2 h3{font-size: 20px;}
.landing_page3_block12_background p{    line-height: 23px;}
.LP3_block12_h1_gradient h1{  font-size: 35px;  line-height: 35px;}
.LP3_block12_text_wrapper{width: calc(88% - 725px);    margin-right: 49px;}
.landing_page3_block2{background-position: left -195px top,right bottom;}
 }

 @media only screen and (max-width: 1380.98px) {
 .landing_page3_block6 .textwrapper p,.landing_page3_block6 .textwrapper h4,.landing_page3_block6 .landing_page3_listwrapper{padding-right:569px}
 }
 @media only screen and (max-width: 1366.98px) {
.landing_page3_bannerblock .lpage_banner_sub_wrapper{width:610px}
.landing_page3_bannerblock .lpage_banner_textwrapper h4{font-size:24px;line-height:29px}
.landing_page3_bannerblock .lpage_banner_sub_wrapper h2{font-size:23px;line-height:30px}
.landing_page3_block2{background-position:left -147px top 20px,right bottom;background-size:630px,cover}
.textwrapper h2{font-size:32px;line-height:45px}
.textwrapper p{line-height:27px;margin-bottom:10px;font-size:20px}
.landing_page3_listwrapper h2{font-size:32px;margin-bottom:10px}
.landing_page3_block8_subwrapper{background-position:left -142px top,right top;padding:30px 40px 25px 540px}
.LP3_b17_text_wrapper h1{font-size: 23px;line-height: 37.18px;}
 }
  @media only screen and (max-width: 1300.98px) {
.landing_page3_block2_subwrapper{width: 770px;}
.landing_page3_list p::after{top: 4px;}
.landing_page3_list p{font-size:16px;line-height:24px;padding:2px 0;padding-left:30px}
.block3-col-text p{line-height: 26px;}
.landing_page3_block3_colcontainer .block3-col{margin:0 15px;border-radius:8px;width:calc(33.33% - 30px)}
 .landing_page3_block6 .textwrapper p,.landing_page3_block6 .textwrapper h4,.landing_page3_block6 .landing_page3_listwrapper{padding-right:494px}
 .landing_page3_block13_text_wrapper h1{font-size: 36px;}
 .landing_page3_block13_text_wrapper li{padding: 5px 0px;}
 .landing_page3_block13_text_wrapper button{    padding: 20px 70px;margin-top: 20px;}
 .landing_page3_block13_text_wrapper li::before{top: 7px;}
 .landing_page3_block14_text li{padding: 5px 0px;}
 .landing_page3_block14_text li::before{top: 7px;}
 .landing_page3_block14_text h1{font-size: 36px;}
 .landing_page3_block15_wrapper h2{font-size: 27px;}
 .LP3_b16_bottom h2{font-size: 31px;}
 .LP3_b16_top h1{font-size: 37px;}
  }
 @media only screen and (max-width: 1280.98px) {
.landing_page3_bannerblock .lpage_arrow_wrapper h2{font-size:26px;line-height:29px}

 }
 @media only screen and (max-width: 1240.98px) {
.landing_page3_bannerblock{background-position:right 200px top,center center}
.landing_page3_block4_subwrapper {
    width: 700px;
}
 }
 @media only screen and (max-width: 1199.98px) {
.landing_page3_bannerblock .lpage_banner_sub_wrapper{width:auto}
.landing_page3_bannerblock{background:rgb(226,240,218);background:linear-gradient(90deg,rgba(226,240,218,1) 0%,rgba(195,227,232,1) 50%,rgba(210,211,230,1) 100%)}
.landing_page3_bannerblock .form_title_wrapper h2 span{margin-left: 5px;}
.landing_page3_bannerblock .lpage_arrow_wrapper{background:rgb(253,154,38);background: linear-gradient(180deg, rgba(8,215,206,1) 0%, rgba(2,201,245,1) 100%);padding:20px}
.landing_page3_bannerblock .lpage_arrow_wrapper h2 br{display: none;}
.landing_page3_block2_subwrapper{width: auto;}
.landing_page3_block2{background-position:left bottom -40px,right bottom;background-size:100%,cover;padding-bottom:65%;margin-bottom: -9%;}
.block3-col-text h3 br{display: none;}
.block3-col-text h3{font-size: 18px;}
.block3-col-text p{line-height:24px;font-size:18px}
.landing_page3_block4_subwrapper{width:auto;margin-top: -28px;}
.landing_page3_block4{background-position:right bottom;background-size:114%;padding-bottom:77%}
.landing_page3_block5_colcontainer{width:auto;flex-wrap:wrap;padding-left:0}
.landing_page3_block5_subwrapper{padding: 25px;padding-bottom: 0;}
.landing_page3_block5_subwrapper .abimage{left:inherit;position:inherit;display:block;margin:0 auto;max-width:100%}
.landing_page3_block6 .textwrapper p,.landing_page3_block6 .textwrapper h4,.landing_page3_block6 .landing_page3_listwrapper{padding-right:0}
.landing_page3_block6_subwrapper .abimage{right:inherit;width:auto;max-width:100%;position:inherit;display:block;margin:0 auto;margin-top:20px}
.landing_page3_block6_subwrapper{padding: 15px; padding-bottom: 0;}
.landing_page3_block7_colcontainer{margin:0 0}
.block7-col{width:calc(100%);margin:10px 0}
.landing_page3_block8_subwrapper{background-position:left bottom,right top;padding:20px;background-size:113%,cover;padding-bottom:60%;margin-bottom: -35px;}
.landing_page3_block7_list h4{bottom:inherit;top:50%;transform:translateY(-50%)}
.landing_page3_block10_subwrapper{  background-image: none , url(https://d37pbainmkhx6o.cloudfront.net/aspireLeadFire/landingpage3/landing_page3_block_10_background.png);}
.landing_page3_block10_textwrapper{    width: auto;    margin-left: 0;}
.landing_page3_block12_background{background: linear-gradient(180deg, rgba(31,54,77,1) 0%, rgba(20,43,59,1) 100%);border-radius: 10px;display: block;padding: 20px;}
.LP3_block12_h1_gradient{width: auto;text-align: center;}
.LP3_block12_text_wrapper{width: auto;margin: auto;text-align: center;}
.LP3_block12_sub_add1 img{display: none;}
.LP3_block12_add{width: auto;justify-content: center;}
.LP3_block12_sub_add2{   background: linear-gradient(180deg, rgba(8,213,244,1) 0%, rgba(6,118,204,1) 100%);border-radius: 10px; width: 550px;    background-position: unset;padding-bottom: 0 ;padding: 10px;text-align: center;}
.LP3_block12_sub_add2_top{justify-content: center;}
.landing_page3_block12_background p{font-size: 25px;padding: 20px 0;line-height: 28px;}
.LP3_block12_h1_gradient h1{font-size: 60px;line-height: 62px;}
.LP3_block12_h1_gradient h1{padding-left: initial;}
.landing_page3_block13{background-image: none !important;}
.landing_page3_block13_text_wrapper{width: auto;}
.landing_page3_block13_text_wrapper ul{width: auto;}
.landing_page3_block13_text_wrapper li::before{height: 8px;width: 8px;top: 8px;}
.landing_page3_block14{background-image: none;}
.landing_page3_block14_text{width: auto; margin-left: auto;}
.landing_page3_block14_text li::before{height: 8px;width: 8px;top: 8px;}
.landing_page3_block14_sub_wrapper p{width: auto;}
.landing_page3_block15_wrapper img{position: initial;width: auto;margin: auto;max-width: 100%;display: block;}
.landing_page3_block15_wrapper{padding-bottom: 0px;padding-right: 30px;}
.landing_page3_block15_wrapper p{padding: 11px 0px 10px 0px;}
.landing_page3_block15_wrapper h2{padding: 0;}
.landing_page3_block15_wrapper img {margin-top: 15px;}
.landing_page3_block16{background-image: none;}
.text_wrapper_b16{    padding-left: initial;}
.LP3_b17_bgimg{background: linear-gradient(180deg, rgb(59 39 123) 0%, rgba(1,0,84,1) 100%);}
.LP3_b17_text_wrapper h1::before{width: 5px;height: 100%;left: -23px;top: 0px;}
.audit_text_wrap {
    width: auto;
}
.audit_back_wrap {
    background-position: center bottom,center center;
    background-size: 71%, cover;
}
.landing_page3_block13_text_wrapper h1{font-size: 39px;}
.landing_page3_block14_text h1{font-size: 39px;}
.landing_page3_block13_text_wrapper_and_bg{padding-right: 0;}
.lpage_banner_main_video_wrapper {
    width:auto;
}
.LP3_b11_ingBG .bgimg2{display: none;}
.LP3_b11_padding_wrapper{width: auto;}
.GB_colors3{margin-right: initial;}
.LP3_b11_gradient_box.GB_colors1{margin-right: initial;}
.LP3_b11_gradient_box{flex: 1 0 32%;}
.LP3_b11_ingBG{background: linear-gradient(0deg, rgba(7,44,87,1) 25%, rgba(12,66,126,1) 100%);}


.landing-container {width: 95%;}
 }






@media only screen and (max-width: 991.98px) {
.landing_page3_block3_colcontainer::after, .landing_page3_block3_colcontainer::before{display: none;}
.landing_page3_block3_colcontainer .block3-col{margin:52px 20px;border-radius:8px;width:calc(100% - 40px)}
.landing_page3_block3_colcontainer{padding:20px 0;padding-bottom:35px}
.landing_page3_block3_colcontainer{flex-wrap: wrap;}
.landing_page3_B10_h1 h1{font-size: 40px;}
.landing_page3_block10_textwrapper p{    font-size: 18px;line-height: 28.02px;}
}
@media only screen and (max-width: 824.98px){
    .landing_page3_block15_wrapper h2{line-height: 28.18px; color: rgba(254,238,2,1);}

}
@media only screen and (max-width: 768.98px) {
 .landing_page3_block2 .landing_page3_list{column-count:1}
.block5-col{width:auto;margin:1% 0;flex:inherit;width:100%}
.block4_col{width:calc(50% - 8px);}
.title_wrapper h1{font-size:32px;line-height:36px}
.landing_page3_block7_list h4{position:inherit;top:inherit;transform:inherit;margin:0 auto;margin-bottom:15px;left:inherit}
.landing_page3_block7_list{padding: 15px;text-align: center;}
.landing_page3_block7_subwrapper{padding: 15px;}
.landing_page3_B10_h1 h1{font-size: 33px;line-height: 40px;}
.landing_page3_block10_textwrapper p{    font-size: 17px;}
.LP3_block12_h1_gradient h1{font-size: 49px;line-height: 50px;}
.landing_page3_block12_background p{font-size: 20px;padding: 15px 0;line-height: 25px;}
.landing_page3_block13_text_wrapper h1{font-size: 33px;}
.landing_page3_block13_text_wrapper h2{font-size: 25px;}
.landing_page3_block13_text_wrapper button{font-size: 28px;}
.landing_page3_block13_text_wrapper button{    padding: 20px 28px;}
.landing_page3_block14_text h1{font-size: 33px;}
.landing_page3_block14_text h2{font-size: 25px;}
.landing_page3_block15_wrapper h1{font-size: 35px;}
.landing_page3_block15_wrapper p{font-size: 18px;}
.landing_page3_block15_wrapper h2{font-size: 25px;}
.LP3_b16_list ul{column-count: initial;}
.LP3_b16_top h1{line-height: 39.94px;}
.LP3_b17_text_wrapper h1{line-height: 30.18px;}
}
@media only screen and (max-width: 675.98px){
    .LP3_b11_gradient_box{    flex: 1 0 51%;margin-right: initial;}
    .LP3_b11_gradient_box.GB_colors0{margin-bottom: 25px;}
    .LP3_b11_border_box .line_gradiyent{display: none;}
    .LP3_b11_border_box p{min-height: initial;}
    .landing_page3_B10_h1{background-size: cover;}
}
@media only screen and (max-width: 557.98px){
.landing_page3_B10_h1{background-size: cover;}
.landing_page3_block15_wrapper h1{font-size: 30px;}

.LP_b5-1_size{ display: inline-block;}
.LP3_b5-1_top_box_lower_color1{ display: block; text-align: center;}
}
@media only screen and (max-width: 480.98px) {
.landing_page3_bannerblock .form_style .form_radio .MuiFormControlLabel-root{width:100%;margin:0}
.landing_page3_bannerblock .form_radio{padding-left:0}
.landing_page3_bannerblock .form_title_wrapper h2 span{margin-left:0;display:block}
.landing_page3_bannerblock .lpage_banner_titlewrapper h1{font-size:32px;line-height:34px}
.landing_page3_bannerblock .lpage_banner_sub_wrapper{padding: 15px;}
.landing_page3_bannerblock{padding: 20px 0;}
.block4_col{width:calc(100% - 8px);}
.landing_page3_block5_subwrapper{padding:15px;padding-bottom:0}
.block5-col-subwrapper2 h2 span{display: block;}
.landing_page3_B10_h1 h1{    font-size: 28px;line-height: 34px;}
.landing_page3_block12_background p{    font-size: 16px;    padding: 15px 0;    line-height: 18px;}
.LP3_block12_h1_gradient h1{font-size: 34px;    line-height: 35px;}
.LP3_block12_sub_add2_top_right h1{font-size: 65px;line-height: 65px;}
.LP3_block12_sub_add2_top_left h1{font-size: 23px;line-height: 30px;}
.LP3_block12_sub_add2_top_left h2{font-size: 14px;line-height: 14px;}
.LP3_block12_sub_add2 h3{font-size: 18px;}
.landing_page3_block13_text_wrapper h1{font-size: 30px;}
.landing_page3_block15_wrapper h2{font-size: 24px;}
.landing_page3_block15_wrapper p{font-size: 17px;line-height: 25px;}
.h1_wrapper h1::before{display: none;}
.landing_page3_block15_wrapper h1{margin-left: -30px;padding-left: 30px;}
.landing_page3_block15_wrapper h1{font-size: 28px;}
.landing_page3_block3_subwrapper{padding: 15px;}
.LP3_b16_top h1{font-size: 33px;}
.LP3_b16_top p{font-size: 18px;}
.LP3_b16_bottom h2{font-size: 25px;}
.LP3_b16_bottom h3{font-size: 20px;line-height: 30.56px;}
.LP3_b16_list li{font-size: 18px;}
.LP3_b16_list li::before{height: 8px;width: 8px;}
.audit_back_wrap {
    background-size: 100%, cover;    padding-bottom: 66%;
}

}
@media only screen and (max-width: 411.98px){
    .landing_page3_block4_sub_textwrapper h1{font-size: 27px;}
}

@media only screen and (max-width: 350.98px){
    .LP3_block12_h1_gradient h1{font-size: 31px;line-height: 31px;}
    .LP3_block12_sub_add2 h3{font-size: 18px;    line-height: 15px;    padding-bottom: 6px; }
    .LP3_block12_sub_add2_top_right h1{    font-size: 58px;    line-height: 59px;}
    .landing_page3_block15_wrapper h1{font-size: 25px;}
    .LP3_b11_border_box p{font-size: 16px;line-height: 22px;}
}





































































































































































