.MuiTableRow-root {
  position: relative;
}

.row_loader {
  position: absolute !important;
  left: 0;
  width: 100%;
}

.notes_details .MuiPaper-root {
  overflow-y: inherit;
}
.view_details .MuiPaper-root {
  overflow-y: inherit;
}

 .close_icon_btn {
  border-radius: 50% !important;
  height: 30px;
  opacity: 1;
  padding: 5px !important;
  position: absolute !important;
  right: -14px;
  top: -17px;
  width: 30px;
  background-color: #fff;
}
/* .view_details .close_icon_btn {
  border-radius: 50% !important;
  height: 30px;
  opacity: 1;
  padding: 5px !important;
  position: absolute !important;
  right: -14px;
  top: -17px;
  width: 30px;
  background-color: #fff;

  .notes_details
} */
.notes_details .notes_header {
  display: flex;
  justify-content: center;
}

.notes_details .hide_table {
  display: none;
}

.view_details .data_container {
  width: fit-content;
}

.view_details .data_block {
  margin: 10px;
}

.view_details .property_container {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}
.view_details .property_container:nth-child(odd) {background: #efefef;}

.list_search {
  margin-bottom: 10px;
  padding: 10px;
}

.list_search .formCls .formInline {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.list_search .form_item div {
  display: flex;
  margin: 0;
}

.list_search .form_item {
  flex: 1 0 31.33333333%;
}
 
.list_search .submitbtnsection {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 15px 0;
  width: 100%;
  align-items: center;
}
.l2_top_banner_right .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 14px;
  background-color: #919699;
  color: #303232;
  font-size: 18px;
  font-weight: var(--main-font-Bold);
}
.l2_top_banner_right .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input span{
  color: #fff;
  font-weight: 400 !important;
}
.landingOne .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input {
  padding: 16.5px 14px;
  background-color: #ffffff;
  color: #303232;
  font-size: 18px;
  font-weight: var(--main-font-Bold);
}
.landingThree .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input {
  padding: 8px 15px;
  background-color: #3f4e55;
  color: #ffffff;
  font-size: 18px;
  font-weight: var(--main-font-Bold);
}