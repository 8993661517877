.landing_page5_bannerblock .form_style .formfild input {
    background-color: #3f4d54;
    color: #fff;
}
.landing_page5_bannerblock {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_newBanner_img1.webp),url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/calender_landingpage5_banner1_1bg.webp);
    background-repeat: no-repeat;
    background-size: auto,cover;
    padding: 45px 0;
    background-position: right 365px top 70px,right top 0;
}
.landing_page5_bannerblock 
.landingOne .formCls .formInline .state .MuiInputBase-root .MuiInputBase-input {
    padding: 16.5px 14px;
    background-color: #3f4d54;
    color: #fff;
    font-size: 18px;
    font-weight: var(--main-font-Bold);
}
.landing_page5_bannerblock .landing-banner-right {
    width: calc(32% - 100px);
    margin-left: 100px;
    margin-top: 0px;
}
.landing_page5_bannerblock .form_style .MuiFormLabel-root {
    font-weight: var(--main-font-Bold);
    color: white !important;
    top: -3px;
    font-size: 18px;}
    .landing_page5_bannerblock 
    .lpage_logowrapper {
       
        margin-bottom: 50px;
    }  
    .landing_page5_bannerblock .lpage_banner_titlewrapper {
        margin-bottom: 35px;
    }
.landing_page5_bannerblock 
.l2submitbtnsection button {    text-shadow: 0 0 2px #0f3a58;
    background: rgb(16,241,239);
    background: linear-gradient(180deg, rgba(16,241,239,1) 0%, rgba(1,189,253,1) 100%);
    border: none;
    border-radius: 5px;
    font-size: 40px;
    line-height: 40px;
    color:black;
    font-weight: var(--main-font-SemiBold);
    text-transform: uppercase;
    padding: 20px;
    width: calc(100%);
}
.landing_page5_bannerblock .lpage_banner_textwrapper p {
    font-weight: var(--main-font-Medium);
    font-size: 29px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 20px;}
.landing_page5_bannerblock .formwrapper {
    background: rgb(40,39,39);
    background: radial-gradient(circle, rgba(40,39,39,1) 0%, rgba(3,3,3,1) 100%);
    padding: 10px;
    /* border: 1px solid #fff; */
    border-radius: 8px;
    /* margin-top: -38px; */
    outline: 15px solid rgba(255,255,255,0.4);
    
}
    .landing_page5_bannerblock 
    .form_style .MuiOutlinedInput-notchedOutline {
        border: 1px solid #77848a !important;
    }
    .landing_page5_bannerblock .lpage_arrow_wrapper h2 {
        color: #fff;
        text-transform: uppercase;
        font-size: 39px;
        line-height: 44px;
        font-weight: var(--main-font-Bold);
        text-shadow: 1px 1px 4px rgb(0 0 0 / 70%);}
    .landing_page5_bannerblock .form_title_wrapper {
        background:#01f0f2;
        padding: 26px 12px;
        text-align: center;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
    }
     .landing_page5_bannerblock .form_title_wrapper::after{
            content: '';
            width: 650px;
            height: 500px;
            background-color: rgba(255,255,255,0.2);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -430px;
            border-radius: 50%;
     }
    .landing_page5_bannerblock .form_title_wrapper h2 span {
        font-size: 30px;
        line-height: 32px;
        text-transform: uppercase;
       
        background: black;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        z-index: 1;
    }
    .landing_page5_bannerblock .lpage_banner_sub_wrapper{position: relative; background-image:url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/l_page5_banner_bg2.webp);background-repeat:no-repeat;background-size:cover;padding:18px;width:836px; border-radius: 10px;}
.landing_page5_bannerblock .lpage_banner_sub_wrapper::after{content:'';width:93%;height:8px;position:absolute;left:50%;transform:translateX(-50%);top:-4px;}


.landing_page5_bannerblock .lpage_banner_textwrapper2 {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/l_page5_banner_bg3.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 20px;
    width: auto;
    font-size: 23px;
    color: white;
    text-align: center;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 14px;
    background-size: cover;
}
    .landing_page5_bannerblock .lpage_banner_textwrapper1 {
        
       background-color: whitesmoke;
        padding: 20px 40px;
        width: fit-content;
        text-align: center;
        color: black;
        margin-bottom: 10px;
        align-items: center;
        margin-left: 20px;
        border-radius: 10px;
        
    }
    .landing_page5_bannerblock .lpage_banner_textwrapper1 p{
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
    }
        .landing_page5_bannerblock .lpage_banner_sub_wrapper h2 span{
            color: white;
            font-size: 55px;
            font-weight: 900;
           

        }
        .landing_page5_bannerblock .lpage_banner_textwrapper p span {
            color: #0070af;
            font-size: 33px;
        }
        .landing_page5_bannerblock .lpage_banner_textwrapper {
            width: 840px;
        }
        .landing_page5_bannerblock .lpage_banner_sub_wrapper h2{
            color: white;
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            line-height: 37px;
            padding-top: 10px;
        }
        .landing_page5_bannerblock .lpage_arrow_wrapper {
            background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_arrow_bg.webp);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 47px 60px 45px 39px;
            position: relative;
        }
         

        .landingpage5_bannerblock2{
            background-image:url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_block2_bg2img_1new.webp) , url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_block2_1bgmain.webp);
            background-repeat: no-repeat;
            background-size:auto , cover;
            padding: 0 0 45px 0;
            background-position: left -93px top -40px,left top;
            /* background-color: #fdfdfc; */
            margin-top: -110px;
            padding-top: 120px;
           
            
        }
        .landing-col-container2{
           
        padding-left: 34%;
        }
        .landingpage5_bannerblock2_left_section{
            width: 40%;
        }
        .landingpage5_bannerblock2_right_section{
            width: 100%;
        }
        /* .landingpage5_bannerblock2_right_subsection{
            background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_block2_img.webp);
            background-size: 100% 100%;
            padding: 25px 60px;
            font-size: 21px;
            font-weight: 600;
            margin-top: 20px;
        } */
        .landingpage5_bannerblock2_right_subsection{
            background: rgb(1,249,236);
            background: linear-gradient(180deg, rgba(1,249,236,1) 0%, rgba(1,190,253,1) 100%);
            padding: 25px 10px;
            margin-top: 20px;
            width: 900px;
            border-radius: 10px;
            margin-bottom: 16px;
            text-align: center;
        }


        
        .landingpage5_bannerblock2_right_subsection label{font-size: 30px;
           
        }
        .landingpage5_bannerblock2_right_section h2{
            font-size: 50px;
            font-weight: 900;
            line-height: 52px;
            margin-bottom: 15px;
}

        .landingpage5_bannerblock2_right_section h3 span{color: #0057a1;     font-size: 34px; line-height: 36px;}
        .landingpage5_bannerblock2_right_section p{font-size: 21px;     margin-top: 21px;}
        .landingpage5_bannerblock2_right_section p span{color: #01befd; font-weight: 600;}
         


        .landingpage5_bannerblock3_subwrapper{
            background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock3_subwrapper_bg.webp);
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 10px;
            padding: 35px;
            padding-bottom: 67px;
            }
            .landingpage5_bannerblock3_firstblock{
                background: rgb(11,232,254);
                background: linear-gradient(180deg, rgba(11,232,254,1) 0%, rgba(70,180,254,1) 100%);
                padding: 10px 15px;
                border-radius: 10px;
                margin-bottom: 18px;
            
             }
             .landingpage5_bannerblock3_firstblock p{
                font-size: 50px;
                line-height: 62px;
                font-weight: var(--main-font-Bold);
                text-align: center;
                text-shadow: 1px 1px 0 #98f0ff;
             }
             .landingpage5_bannerblock3_secondblock{
                border: 1px solid whitesmoke;
                border-radius: 10px;
                display: flex;
                justify-content: space-between;
                padding: 10px;
                /* margin: 10px 30px; */
                margin-bottom: 26px;
             }
             .landingpage5_bannerblock3_secondblock_leftsection{
                background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock3_secondblock_leftsection_1img.webp),url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock3_secondblock_bg.webp);
                background-size: auto 100%, cover;
                width: 48%;
                border: 7px solid rgb(255 255 255 / 26%);
                background-position: right bottom;
                border-radius: 10px;
                background-clip: content-box;
                background-repeat: no-repeat;
             }
             .landingpage5_bannerblock3_secondblock_leftsection p{
                font-size: 28px;
                font-weight: 700;
                padding-left: 51px;
                padding-top: 50px;
                padding-bottom: 50px;
                line-height: 35px;
             }
             .landingpage5_bannerblock3_secondblock_leftsection span{
                color: #fe6d01;
                font-size: 58px;
             }
             .landingpage5_bannerblock3_secondblock_leftsection strong {
                font-weight: 500;
             }
             .landingpage5_bannerblock3_secondblock_rightsection{
                background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock3_secondblock_rightsection_img.webp),url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock3_secondblock_bg.webp);
                background-size: auto 100%, cover;
                width: 48%;
                border: 7px solid rgb(255 255 255 / 26%);
                background-position: right bottom;
                border-radius: 10px;
                background-clip: content-box;
                background-repeat: no-repeat;
             }
             .landingpage5_bannerblock3_secondblock_rightsection p{
                font-size: 28px;
                font-weight: 700;
                padding-left: 51px;
                padding-top: 50px;
                padding-bottom: 50px;
                line-height: 35px;
             }
             
             .landingpage5_bannerblock3_secondblock_rightsection span{
                color: #fe6d01;
                font-size: 58px;
             }
             .landingpage5_bannerblock3_secondblock_rightsection strong{
                font-weight: 500;
             }


             .landingpage5_bannerblock3_thirdblock{
                background : rgb(254,253,236);
                background: linear-gradient(90deg, rgba(254,253,236,1) 0%, rgba(251,254,242,1) 46%, rgba(237,253,255,1) 100%);
                margin: 0 auto;
                /* margin: 20px 33px; */
                border-radius: 15px;
                padding: 30px 50px;
            
             }
             .landingpage5_bannerblock3_thirdblock h2{
                text-align: center;
                font-size: 60px;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 64px;
                margin-bottom: 20px;
             }
             .landingpage5_bannerblock3_thirdblock ul{
                list-style: none;
                column-count: 6;
                column-gap: 30px;
             }
             .landingpage5_bannerblock3_thirdblock li{
                position: relative;
                font-size: 18px;
                font-weight: 600;
                padding: 0 0 10px 40px;
             }
             .landingpage5_bannerblock3_thirdblock li::before{
                content: "";
                position: absolute;
                left: 0px;
                top: 4px;
                background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_block3_li_logo.png);
                background-size: 100%;
                background-position: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                box-shadow: 2px 4px 4px #00000080;
             }
             .hr {
              
                background: linear-gradient(90deg, rgb(248 249 238 / 0%) 0%, rgba(177,185,193,0.4654236694677871) 48%, rgb(254 254 243 / 0%) 100%);
                height: 3px;
                width: 100%;
                margin-bottom: 30px;
            
             }
             .landingpage5_bannerblock3_fourblock{
                background: rgb(1,243,237);
                background: linear-gradient(180deg, rgba(1,243,237,1) 0%, rgba(1,191,252,1) 100%);
                width: fit-content;
                margin: 0 auto;
                border-radius: 10px;
                margin-top: -37px;
                position: relative;
             }
             .landingpage5_bannerblock3_fourblock h2{
                font-size: 35px;
                font-weight: 700;
                padding: 15px 25px;
             }


             .landingpage5_bannerblock4{
                background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock4_1img.webp),url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock4_1bg.webp);
                background-repeat: no-repeat;
                background-size: auto , auto;
                padding: 45px 0;
                background-position: right 44px bottom -125px, right top;
                margin-top: -42px;

            
             }
             .landingpage5_bannerblock4 h2{
                font-size: 50px;
                font-weight: 800;
                line-height: 56px;
                margin-bottom: 20px;
                margin-top: 20px;
             }
             .landingpage5_bannerblock4 p{
                font-size:30px ;
                font-weight: 600; line-height: 38px;
             }
             .landingpage5_bannerblock4 span{
                color: #0057a1;
                font-weight: 600;
             }
             .landingpage5_bannerblock4 strong{
                color: #01befd;
                font-weight: 600;
             }

             .landingpage5_bannerblock5{
                /* background: rgb(251,251,249);
                background: linear-gradient(90deg, rgba(251,251,249,1) 0%, rgba(251,251,249,1) 100%); */
             }
             .landingpage5_bannerblock5_firstblock{
                background-color:#ffffff;
                width: fit-content;
                margin-bottom: 0px;
                border-radius: 10px;
                box-shadow: 1px 1px 10px #c7bcbc;
               
             }
             .landingpage5_bannerblock5_firstblock h3{
                text-align: center;
                font-size: 40px;
                font-weight: 600;
                color: #143159;
                padding: 15px; padding-bottom: 5px;
             }
             .landingpage5_bannerblock5_firstblock p{
                font-size: 24px;
                padding: 0 70px 52px 70px; text-align: center;
             }
             .landing-col-container4{ padding-right: 630px;
             }
             .landingpage5_bannerblock6_mainwrapper{ background-image:url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock6_1bg.webp), linear-gradient(90deg, rgba(6,139,237,1) 0%, rgba(6,139,237,1) 50%, rgba(1,188,255,1) 100%); ;
                background-size: cover; background-repeat: no-repeat;  padding: 20px 80px; border-radius: 10px; background-blend-mode: screen; margin-top: 30px;}
            .landingpage5_bannerblock6 h2{text-align: center; color: white; font-size: 45px; font-weight: 600;padding-bottom: 13px;    padding-top: 10px;line-height: 53px;}
            .landingpage5_bannerblock6_firstblock{background-color: #254785; padding: 20px;    border:7px solid #87ceebd9; border-radius: 20px;margin-bottom: 40px; }
            .landingpage5_bannerblock6_sub_firstblock{padding: 20px;background-color: white; border-radius: 10px;padding-top: 0;padding-bottom: 0;display: flex;}
            .landingpage5_bannerblock6_sub_firstblock img{width: 20%;padding: 10px;}
           
            .landingpage5_bannerblock7{background-image:url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock7_1img.webp),url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock7_bg.webp);
                background-repeat: no-repeat; background-size: auto , auto; background-position: left 40px bottom -21px, left top;background-color: #fafaf6;}
            .landingpage5_bannerblock7 h2{padding-left: 50%;font-size: 50px; line-height: 59px;padding-top: 20px;margin-bottom: 10px;}
            .landingpage5_bannerblock7 h3{font-size: 40px; padding-left: 50%;font-weight: 500;line-height: 45px;margin-bottom: 5px;}
            .landingpage5_bannerblock7 h3 span{color: #0057a1;}
            .landingpage5_bannerblock7 p{padding-left: 50%; font-size: 24px; font-weight: 500;    padding-bottom: 30px;}
            .landingpage5_bannerblock7 p span{color: #01befd;}
            .landingpage5_bannerblock9{
               
                background-color: #f4f4ec;
                                             
            }
            .landingpage5_bannerblock8{background-color: #f7f7f0;}
            .landingpage5_bannerblock8_mainwrapper{background: rgb(243,251,255);background: linear-gradient(90deg, rgba(243,251,255,1) 0%, rgba(255,255,249,1) 100%);    box-shadow: 0 0 10px #9fa0a1;padding: 30px 30px 60px 30px;position: relative;}
            .landingpage5_bannerblock8_sub_mainwrapper{display: flex;justify-content: space-evenly;flex-wrap: wrap;}
            .landingpage5_bannerblock8_mainwrapper h2{    text-align: center;font-size: 50px;margin-bottom: 20px;    color: #0057a1}
            .landingpage5_bannerblock8_step1{display: flex;border: 1px solid lightgray;width: 48%;margin-bottom: 20px;border-radius: 10px;}
            .landingpage5_bannerblock8_step1_img{    margin-top: -8px;margin-bottom: -8px; margin-left: -8px;background-color: #1e4791;    display: flex; flex-direction: column; justify-content: center;align-items: center;position: relative;}
            .landingpage5_bannerblock8_step2{display: flex;border: 1px solid lightgray;width: 48%; margin-bottom: 20px;border-radius: 10px;}
            .landingpage5_bannerblock8_step2_img{    margin-top: -8px;margin-bottom: -8px; margin-left: -8px;background-color: #1e4791;    display: flex; flex-direction: column; justify-content: center; align-items: center;position: relative;}
            .landingpage5_bannerblock8_step3{display: flex;border: 1px solid lightgray;width: 48%; margin-bottom: 20px;border-radius: 10px;}
            .landingpage5_bannerblock8_step3_img{    margin-top: -8px;margin-bottom: -8px; margin-left: -8px;background-color: #1e4791;    display: flex; flex-direction: column; justify-content: center; align-items: center;position: relative;}
            .landingpage5_bannerblock8_step4{display: flex;border: 1px solid lightgray;width: 48%;margin-bottom: 20px;border-radius: 10px;}
            .landingpage5_bannerblock8_step4_img{    margin-top: -8px;margin-bottom: -8px; margin-left: -8px;background-color: #1e4791;    display: flex; flex-direction: column; justify-content: center; align-items: center;position: relative;}
            .landingpage5_bannerblock8_step5{display: flex;border: 1px solid lightgray;width: 48%;margin-bottom: 20px;border-radius: 10px;}
            .landingpage5_bannerblock8_step5_img{    margin-top: -8px;margin-bottom: -8px; margin-left: -8px;background-color: #1e4791;    display: flex; flex-direction: column;  justify-content: center;  align-items: center;position: relative;}
            .landingpage5_bannerblock8_step6{display: flex;border: 1px solid lightgray;width: 48%;margin-bottom: 20px;border-radius: 10px;}
            .landingpage5_bannerblock8_step6_img{    margin-top: -8px;margin-bottom: -8px; margin-left: -8px;background-color: #1e4791;    display: flex; flex-direction: column;  justify-content: center;  align-items: center;position: relative;}
            .landingpage5_bannerblock8_step1_img h4{color: white;text-align: center;padding: 5px;}
            .landingpage5_bannerblock8_step2_img h4{color: white;text-align: center;padding: 5px;}
            .landingpage5_bannerblock8_step3_img h4{color: white;text-align: center;padding: 5px;}
            .landingpage5_bannerblock8_step4_img h4{color: white;text-align: center;padding: 5px;}
            .landingpage5_bannerblock8_step5_img h4{color: white;text-align: center;padding: 5px;}
            .landingpage5_bannerblock8_step6_img h4{color: white;text-align: center;padding: 5px;}
            .landingpage5_bannerblock8_step1_img::after{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo1.png);top: 0;    right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step1_img::before{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo2.png);bottom: 0;right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step2_img::after{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo1.png);top: 0;    right: -7px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step2_img::before{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo2.png);bottom: 0;right: -7px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step3_img::after{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo1.png);top: 0;    right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step3_img::before{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo2.png);bottom: 0;right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step4_img::after{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo1.png);top: 0;    right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step4_img::before{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo2.png);bottom: 0;right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step5_img::after{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo1.png);top: 0;    right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step5_img::before{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo2.png);bottom: 0;right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step6_img::after{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo1.png);top: 0;    right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step6_img::before{content: " ";position: absolute;background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_logo2.png);bottom: 0;right: -6px;width: 7px;height: 7px;  background-repeat: no-repeat;}
            .landingpage5_bannerblock8_step1_img img{width: 133px;padding: 10px;}
            .landingpage5_bannerblock8_step2_img img{width: 133px;padding: 10px;}
            .landingpage5_bannerblock8_step3_img img{width: 133px;padding: 10px;}
            .landingpage5_bannerblock8_step4_img img{width: 133px;padding: 10px;}
            .landingpage5_bannerblock8_step5_img img{width: 133px;padding: 10px;}
            .landingpage5_bannerblock8_step6_img img{width: 133px;padding: 10px;}
            .landingpage5_bannerblock8_mainwrapper h3{font-size: 27px; line-height: 32px;padding-bottom: 5px;}
            .landingpage5_bannerblock8_mainwrapper p{font-size: 22px; line-height: 25px;}
            .landingpage5_bannerblock8_mainwrapper p span{color: #01befd;}
            .landingpage5_bannerblock8_step1_text{padding: 20px;}
            .landingpage5_bannerblock8_step2_text{padding: 20px;}
            .landingpage5_bannerblock8_step3_text{padding: 20px;}
            .landingpage5_bannerblock8_step4_text{padding: 20px;}
            .landingpage5_bannerblock8_step5_text{padding: 15px;}
            .landingpage5_bannerblock8_step6_text{padding: 20px;}
            .landingpage5_bannerblock8_mainwrapper .bluebgtitle{position: absolute;left: 50%;transform: translatex(-50%); bottom: -60px;    width: 910px; text-align: center;}

             .landingpage5_bannerblock5_firstblock p span{ position: relative;}
             .landingpage5_bannerblock5_firstblock p span img{ position: absolute;}   

             .landingpage5_bannerblock5_firstblock p span:first-of-type img{    top: -40px;
                left: -70px;}





















            .landingpage5_bannerblock9_subwrapper1{
                background-image:url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_1bannerblock9_1imgtwo.webp);
                background-position: right bottom;
                background-repeat: no-repeat;
                padding-top: 60px;
                padding-bottom: 20px;
            }
            .landingpage5_bannerblock9_subwrapper2{
                background-image:
                url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock9_2img.webp),
                url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock9_2bgone.webp); 
                background-position:left 90px bottom ,left bottom -88px;
                background-repeat: no-repeat;
                padding-top: 30px;
                padding-bottom: 55px;
            }
            
                                                   
             .landingpage5_bannerblock9_mainwrapper_firstpart{width: 55%;margin-top: 18px;}
             .landingpage5_bannerblock9_mainwrapper_firstpart h2{font-size: 50px;font-weight: 800;}
             .landingpage5_bannerblock9_mainwrapper_firstpart h3{font-size: 30px;line-height: 35px;margin-bottom: 12px;}
             .landingpage5_bannerblock9_mainwrapper_firstpart p{font-size: 22px;}  
             .landingpage5_bannerblock9_mainwrapper_firstpart h3 span{color: #0057a1;} 
             .landingpage5_bannerblock9_mainwrapper_firstpart p span{color: #01bdfb;}   
             .landingpage5_bannerblock9_mainwrapper_secondpart{padding-left: 44%;} 
             .landingpage5_bannerblock9_mainwrapper_secondpart h2{font-size: 40px;color:#0057a1 ;line-height: 45px;}
             .landingpage5_bannerblock9_mainwrapper_secondpart p{font-size: 22px;} 
             .landingpage5_bannerblock9_mainwrapper_secondpart p span{color: #01bcfa;}
.landingpage5textwrapper h1{
        font-size: 50px;
    line-height: 62px;
    color: #fff;
    font-weight: var(--main-font-Bold);
    margin-bottom: 28px;
}
.landingpage5textwrapper p{
    font-size: 24px;
    line-height: 33px;
    color: #fff;
    font-weight: var(--main-font-Medium);
    margin-bottom: 15px;
}

.landingpage5textwrapper h1 span{color: #01befd;}
.bluebgtitle {}
.bluebgtitle{
    background: rgb(1,254,234);
    background: linear-gradient(180deg, rgba(1,254,234,1) 0%, rgba(1,183,255,1) 100%);
    padding: 20px 30px;
    border-radius: 18px;
    width: fit-content;
   
}
.bluebgtitle p{
    font-size: 32px;
    line-height: 36px;
    color: #283801;
    font-weight: var(--main-font-Bold);
    text-shadow: 1px 1px 0 #01faeb;
     margin: 0;
}
.landingpage5_lstblock_subwrapper{
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/lpage5lstimage2.jpg);
    background-size: cover;
    padding: 40px;
    padding-left: 400px;
    position: relative;
    border-radius: 5px;
}
.landingpage5_lstblock_subwrapper .abimage{
    display: block;
    max-width: 100%;
    position: absolute;
    left: 20px;
    bottom: 0;
}

.landingpage5_lstblock_subwrapper .abimage{display: block;max-width: 100%; position: absolute;  left: 20px;  bottom: 0;}



.landingpage5_block12_subwrapper{
    background: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/lpage5block12_image1.png), linear-gradient(90deg, rgba(14,57,135,1) 0%, rgba(14,57,135,1) 40%, rgba(61,117,176,1) 100%);
    background-repeat: no-repeat;
    background-position: right -120px bottom, right top;
    padding: 28px;
    border-radius: 8px;
    padding-right: 434px;
    background-size: auto 100%;
    border: 7px solid #e3e3db;
    margin-bottom: 41px;
}
.landingpage5_block12_subwrapper .bluebgtitle{margin-bottom: 10px;}
.pr{padding-right: 340px;}
.landingpage5_block12_subwrapper .landingpage5textwrapper p{margin-bottom: 30px;}
.landingpage5_block12_subwrapper .landingpage5textwrapper .bluebgtitle p{margin-bottom: 0px;}

.demo5img{ width: auto; max-width: 100%;}

.demo5Con{background: rgb(244,251,255);
    background: linear-gradient(132deg, rgba(244,251,255,1) 0%, rgba(253,254,242,1) 100%);      position: relative;   box-shadow: 1px 1px 10px #c7bcbc; border-radius: 10px;     width: 1795px; display: block; margin: 0 auto; margin-top: -5px;}
    .demo5Con img{ width: 100%; position: relative;}

    .landingpage5_lstblock{
        margin: 0;
    margin-top: -18px;
    background: #eef2ea;
    padding: 20px 0;
    overflow: hidden;
    }
    .landingpage5_block12 {
    background: #eef2ea;
    overflow: hidden;
}

  .demo5Con .bluebgtitle{position: absolute; left: 50%;
    transform: translatex(-50%);
    bottom: -30px;}

.landing_page5_bannerblock .lpage_arrow_wrapper::after{z-index: -1;}
/* -----------raj-----vdowrpr5--css---starts---------------------- */
.l5_videowrapper {width: 820px;}
.l5_videowrapper .l5_vdo_txt_wrp {background-image: url(https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/l5_txt_bg.png);background-repeat: no-repeat; background-size: cover; padding: 15px 30px; width: fit-content; margin: 0 auto;text-align: center;border-radius: 15px 15px 0 0;}
.l5_videowrapper .l5_vdo_txt_wrp h2{ color: white;
    font-size: 48px;
    font-weight: 900; line-height: 58px; margin-bottom: 6px;}
.l5_videowrapper .l5_vdo_txt_wrp h5{ color: white;
        font-size: 24px;
        font-weight: 600; line-height: 31px;}  
.l5_videowrapper .lpage_banner_sub_wrapper  { width: 100% !important;padding: 0; background-image: none;}


/* -----------raj-----vdowrpr5--css---ends---------------------- */


@media only screen and (max-width: 1900px) {
    .landingpage5_bannerblock2{background-position: left -115px top -40px,left top;}
}

@media only screen and (max-width: 1843px) {
.landing_page5_bannerblock{background-size:auto 74%,cover}
.landingpage5_bannerblock2_right_section h2{font-size:42px;font-weight:900}
.landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size:36px}
.landingpage5_bannerblock8_mainwrapper h3 { font-size: 25px;}
.landingpage5_bannerblock8_mainwrapper h3 br{display: none;}
.landingpage5_bannerblock8_mainwrapper p br{display: none;}

.demo5Con{ width: 96%;}
}
@media only screen and (max-width: 1820px) {
.landingpage5_bannerblock2_right_section h2{font-size:44px;font-weight:900}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:37%}

.landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size:34px}

.landing_page5_bannerblock .lpage_arrow_wrapper{margin-top: 44px;}
}
@media only screen and (max-width:1778px) {
.landingpage5_bannerblock3_firstblock p{font-size:38px;line-height:50px}
.landing_page5_bannerblock{background-size:auto 60%,cover}

.landing-col-container4 {
    padding-right: 700px;
}
.landingpage5_bannerblock4 span br{ display: none;}

}
@media only screen and (max-width:1745px){
.landingpage5_bannerblock4 h2{    font-size: 48px;}
.landingpage5_bannerblock9_mainwrapper_secondpart{    padding-left: 42%;margin: 0;}
.landingpage5_bannerblock9_mainwrapper_secondpart h2{font-size: 38px;    line-height: 42px; margin-bottom: 15px;}

.landingpage5_bannerblock8_mainwrapper h3 { font-size: 24px;}
.landingpage5_bannerblock9_subwrapper2{    background-position: left 40px bottom ,left bottom -88px;}
}
@media only screen and (max-width: 1700px) {
.landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size:30px;line-height: 37px;}
.landingpage5_bannerblock9_mainwrapper_secondpart {
    padding-left: 50%;
    margin: 0;
}
.landingpage5_bannerblock9_subwrapper2 {
    background-position: left 70px bottom ,left bottom -88px;
}
.landingpage5_bannerblock2 {
    background-position: left -141px top -40px,left top;
}
}
@media only screen and (max-width: 1683px) {
.landingpage5_bannerblock2_right_section h2{font-size:43px;font-weight:900}
/* .landingpage5_bannerblock2{padding:33px 0;margin-top:-46px} */
.landingpage5_bannerblock4 h2{font-size: 45px; line-height: 50px;}
.landingpage5_bannerblock4 p{font-size: 28px;}
}
@media only screen and (max-width: 1666px) {
    .landingpage5_bannerblock9_subwrapper1{background-position: right -74px bottom;}
    .landingpage5_bannerblock9_subwrapper2{
        background-position: left -160px bottom -41px,left bottom -88px;
        background-size: auto 100%, auto;
    }
}
@media only screen and (max-width: 1652px) {
/* .landingpage5_bannerblock2{margin-top:-55px} */

.landing_page5_bannerblock{background-position:right 304px top,right top}
.landingpage5_bannerblock7{background-position: left 10px bottom -21px, left top}
/* .landingpage5_bannerblock9_mainwrapper_secondpart {padding-left: 45%;} */
.landingpage5_bannerblock9_mainwrapper_firstpart h3 span br{display: none;}
}
@media only screen and (max-width: 1600px) {
.landingpage5_bannerblock2_right_section h2{font-size:40px;font-weight:900}

.landingpage5_bannerblock2 .landing-col-container2{padding-left:40%}
.pr{padding-right:200px}
.landingpage5_block12_subwrapper{background-position: right -300px bottom, center center;padding-right: 374px;}
.landingpage5_bannerblock2_right_subsection label {
    font-size: 25px;
}
.landingpage5_bannerblock2_right_subsection{width: 100%;}
}
@media only screen and (max-width: 1577px) {
.landingpage5_bannerblock2_right_section h2{font-size:39px;font-weight:900}
.landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size:30px}
.landingpage5_bannerblock4 h2{font-size: 41px;}
.landingpage5_bannerblock7 h2{padding-left: 52%;    font-size: 45px;}
.landingpage5_bannerblock7 h3 { font-size: 37px; padding-left: 52%;}
.landingpage5_bannerblock7 p { padding-left: 52%; font-size: 22px;}
.landingpage5_bannerblock2_right_subsection label {
    font-size: 25px;
}

}
@media only screen and (max-width: 1560px) {
    .landingpage5_bannerblock9_subwrapper2{    background-position: left -200px bottom -41px,left bottom -88px;}
}
@media only screen and (max-width: 1550px) {
.landing_page5_bannerblock .lpage_banner_sub_wrapper{width:781px}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:41%}

.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:33px}
.landingpage5_bannerblock2_right_section h2{font-size:38px;font-weight:900}
.landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size:28px}
.landingpage5_bannerblock9_mainwrapper_secondpart {    padding-left: 48%;}
.landing_page5_bannerblock .lpage_arrow_wrapper{margin-top: -11px;}
.landing_page5_bannerblock .formwrapper {margin-top: 0px;}
.l5_videowrapper .l5_vdo_txt_wrp h2 {font-size: 40px; line-height: 46px;}
.l5_videowrapper .l5_vdo_txt_wrp h5 {font-size: 21px; line-height: 27px;}
.l5_videowrapper {
    margin-bottom: 45px;
    width: 785px;
}
}
@media only screen and (max-width: 1540px) {
.landing_page5_bannerblock .landing-banner-right{width:calc(35% - 43px);margin-left:43px;margin-top:166px}
.landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size:20px;line-height:28px}
.landing_page5_bannerblock .form_title_wrapper h2 span{font-size:27px}

.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:31px}
.landingpage5_bannerblock2_right_section h2{font-size:36px;font-weight:900}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:42%}


.landing_page5_bannerblock .lpage_banner_sub_titlewrapper{width: 100%;}
}
@media only screen and (max-width: 1513px) {
.landingpage5_bannerblock2_right_section h2{font-size:35px;font-weight:900}
.landingpage5_bannerblock3_thirdblock ul{column-count:5}
.landingpage5_bannerblock7 p {  padding-left: 54%;}
.landingpage5_bannerblock7 h3 { padding-left: 54%;}
.landingpage5_bannerblock7 h2 { padding-left: 54%;}
.landingpage5_bannerblock7 h2 br{display: none;}
.landingpage5_bannerblock9_mainwrapper_firstpart{    width: 53%;} 
}
@media only screen and (max-width: 1480px) {
.landingpage5textwrapper h1{font-size:36px;line-height:43px}
.bluebgtitle p{font-size:28px}
.landingpage5_bannerblock9_mainwrapper_firstpart{width: 51%;}
.landingpage5_bannerblock9_mainwrapper_firstpart h2{font-size: 48px;}
.landingpage5_bannerblock9_subwrapper2 {
    background-position: left -340px bottom -41px,left bottom -88px;
}
.landingpage5_bannerblock9_mainwrapper_secondpart {
    padding-left: 43%;
}
.landingpage5_lstblock_subwrapper .abimage{
    width: 265px;
}
.landingpage5_bannerblock2 {
    background-position: left -169px top -40px,left top;
}
}
@media only screen and (max-width: 1470px) {

.landingpage5_bannerblock2_right_section h2{font-size:33px;font-weight:900}
.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:29px}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:43%}
.landingpage5_bannerblock3_secondblock_leftsection p{font-size:  22px;padding-left:15px}
.landingpage5_bannerblock3_secondblock_rightsection p{font-size:22px;padding-left:15px}
}
@media only screen and (max-width: 1440px) {
.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:28px}
.landingpage5_bannerblock2_right_section h2{font-size:32px;font-weight:900;line-height:48px}
.landing_page5_bannerblock .lpage_banner_sub_wrapper{width:688px}
.landingpage5_bannerblock7 p {  padding-left: 56%;    font-size: 20px;}
.landingpage5_bannerblock7 h3 { padding-left: 56%;    font-size: 34px;}
.landingpage5_bannerblock7 h2 { padding-left: 56%;    font-size: 42px;    line-height: 49px;}
.landingpage5_bannerblock7 {background-position: left -38px bottom -21px, left top;}
.landingpage5_bannerblock9_mainwrapper_secondpart{    padding-left: 49%;}

}
@media only screen and (max-width: 1400px) {
.landing_page5_bannerblock .lpage_banner_sub_wrapper{width:653px}
.landing_page5_bannerblock .form_title_wrapper h2 span{font-size:25px}
.landing_page5_bannerblock .landing-banner-right{margin-top:138px}
.landingpage5_bannerblock2_right_section p{font-size:20px}
.landingpage5_bannerblock2_right_section h2{font-size:31px;font-weight:900}
.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:27px}

.landingpage5_bannerblock2 .landing-col-container2{padding-left:45%}
.landingpage5_bannerblock9_mainwrapper_secondpart p{font-size: 20px;}
.landingpage5_bannerblock9_mainwrapper_secondpart{    padding-left: 52%;}
.landingpage5_bannerblock9_mainwrapper_firstpart p{font-size: 20px;}
.landingpage5_bannerblock9_mainwrapper_firstpart h3{    font-size: 29px;}
.landingpage5_bannerblock9_mainwrapper_firstpart h2{font-size: 44px;    line-height: 48px;margin-bottom: 10px;}
.landingpage5_bannerblock9_mainwrapper_firstpart{    width: 49%;}
.landingpage5_bannerblock2_right_subsection label {
    font-size: 23px;
}
}

@media only screen and (max-width: 1378px) {
.landing_page5_bannerblock .lpage_banner_sub_wrapper{width:653px}
.landing_page5_bannerblock{background-position:right 231px top,right top}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:48%}
.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:25px}
.landingpage5_bannerblock2_right_section h2{font-size:31px;font-weight:900;line-height:47px}

.landingpage5_bannerblock7 p {  padding-left: 59%;}
.landingpage5_bannerblock7 h3 { padding-left: 59%;}
.landingpage5_bannerblock7 h2 { padding-left: 59%;}
}
@media only screen and (max-width: 1366px) {
.landingpage5_block12_subwrapper .landingpage5textwrapper p{margin-bottom:20px}
.landingpage5textwrapper p{font-size:20px;line-height:35px;}
.landing_page5_bannerblock .lpage_arrow_wrapper h2 {font-size: 20px;line-height: 29px;}
.demo5Con .bluebgtitle p{font-size:20px;line-height:27px;}
.landingpage5_bannerblock8_mainwrapper h2{font-size: 45px;}
}

@media only screen and (max-width: 1346px){
    .landing_page5_bannerblock .lpage_banner_textwrapper1{    padding: 7px 19px;}
    }

@media only screen and (max-width: 1331px) {
.landingpage5_bannerblock2_right_section h2{font-size:30px;font-weight:900;line-height:42px}
.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:22px}
.landingpage5_bannerblock2_right_section p{font-size:18px}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:49%}

.landingpage5_bannerblock2_right_subsection{    padding: 25px 47px;}
.landingpage5_bannerblock9_mainwrapper_secondpart{    padding-left: 54%;}
.landingpage5_bannerblock9_mainwrapper_firstpart h3{    font-size: 27px;}
.landingpage5_bannerblock9_mainwrapper_firstpart h2{    font-size: 40px;}

.l5_videowrapper {
    margin-bottom: 45px;
    width: 725px;
}
}

@media only screen and (max-width: 1314px) {
.landingpage5_bannerblock2_right_section p{font-size:17px}

.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:20px}
.landingpage5_bannerblock2_right_section h2{font-size:29px;font-weight:900;line-height:39px}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:49%}
/* .landingpage5_bannerblock2{margin-top:-30px} */

.landingpage5_bannerblock7 p {  padding-left: 61%;font-size: 18px;}
.landingpage5_bannerblock7 h3 { padding-left: 61%;font-size: 30px;}
.landingpage5_bannerblock7 h2 { padding-left: 61%;font-size: 40px;}
}
@media only screen and (max-width: 1300px) {
.landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size:18px;line-height:24px}
.landing_page5_bannerblock .landing-banner-right{margin-top:90px}
.landing_page5_bannerblock .lpage_banner_sub_wrapper h2 span{font-size:47px}
.landing_page5_bannerblock .lpage_banner_sub_wrapper h2{font-size:25px}
.landing_page5_bannerblock .lpage_banner_textwrapper1 p{font-size:28px}
.landingpage5_bannerblock3_thirdblock ul{column-count:5}
.landing_page5_bannerblock .lpage_banner_sub_wrapper{width:653px}

    
}
@media only screen and (max-width: 1290px) {
.landingpage5_bannerblock2_right_section h2{font-size:28px;font-weight:900;line-height:35px}
.landingpage5_bannerblock3_thirdblock ul{column-count:5}
}
@media only screen and (max-width: 1275px) {
.landing_page5_bannerblock .lpage_banner_sub_wrapper{width:653px}
.landing_page5_bannerblock .lpage_banner_sub_wrapper h2{font-size:23px}
.landing_page5_bannerblock .lpage_banner_textwrapper p span{font-size:28px}
.landingpage5_bannerblock3_thirdblock ul{column-count:5}
.landingpage5_bannerblock3_secondblock_leftsection p{font-size:18px;padding-left:5px}
.landingpage5_bannerblock3_secondblock_rightsection p{font-size:18px;padding-left:5px}
.landingpage5_bannerblock9_mainwrapper_secondpart {    padding-left: 56%;}
.landingpage5_bannerblock9_mainwrapper_firstpart h2{    font-size: 39px;}
.landingpage5_bannerblock9_mainwrapper_firstpart{width: 47%;}
.landingpage5_bannerblock8_mainwrapper p{    font-size: 18px;}
.bluebgtitle p{font-size: 28px;}
.landingpage5_bannerblock8_mainwrapper h3{font-size: 22px;}
}
@media only screen and (max-width: 1262px) {
.landingpage5_bannerblock2_right_section p{font-size:16px}

.landingpage5_bannerblock2_right_section h2{font-size:24px;font-weight:900}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:50%}
.landingpage5_bannerblock3_thirdblock ul{column-count:5}
.landingpage5_bannerblock7 p {  padding-left: 63%;font-size: 18px;}
.landingpage5_bannerblock7 h3 { padding-left: 63%;font-size: 30px;}
.landingpage5_bannerblock7 h2 { padding-left: 63%;font-size: 40px;}
.landingpage5_bannerblock9_subwrapper2 {
    background-position: left -410px bottom -41px,left bottom -88px;
}
}

@media only screen and (max-width: 1248px) {
.landingpage5_bannerblock2_right_section h2{font-size:22px;font-weight:900;line-height:33px}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:50%}

.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:19px;line-height:22px}
.landingpage5_bannerblock3_thirdblock ul{column-count:4}
.landingpage5_bannerblock3_thirdblock h2{text-align:center;font-size:50px;font-weight:600}
.landingpage5_bannerblock7 p {  padding-left: 64%;font-size: 18px;}
.landingpage5_bannerblock7 h3 { padding-left: 64%;font-size: 28px;}
.landingpage5_bannerblock7 h2 { padding-left: 64%;font-size: 38px;}
.landingpage5_bannerblock9_mainwrapper_secondpart h2{    font-size: 37px;}
.landingpage5_bannerblock9_mainwrapper_secondpart{    padding-left: 58%;}
.landingpage5_bannerblock9_mainwrapper_firstpart h2{font-size: 37px;}
.landingpage5_bannerblock9_mainwrapper_firstpart{width: 45%;}
.landingpage5_bannerblock9_subwrapper2 {
    background-position: left -500px bottom -41px,left bottom -88px;
}
}
@media only screen and (max-width: 1199px) {
.landingpage5_block12 {    margin-top: -38px;}
.landing_page5_bannerblock .lpage_banner_textwrapper1{margin-left: 0px;}
.demo5Con { overflow: hidden;}
.demo5Con .bluebgtitle { position: inherit;  left: 0;  transform: inherit;  bottom: 0;  display: block; width: 90%;  margin: 0 auto;  margin-bottom: 15px; margin-top: -3%; text-align: center;}
.landingpage5_bannerblock3_subwrapper{    padding: 15px;}
.demo5Con { overflow: hidden;}
.demo5Con .bluebgtitle { position: inherit; left: 0; transform: inherit; bottom: 0;  display: block; width: 90%; margin: 0 auto; margin-bottom: 15px; margin-top: -3%; text-align: center; }
.landingpage5_bannerblock4 {  background-image: url(https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock4_1bg.webp); background-repeat: no-repeat;background-size: cover;   }
.landing-col-container4 { padding-right: 0; }
.landingpage5_bannerblock4{ padding: 0px 10px 25px 10px;background-image: none;margin-top: 0;}
.landingpage5_bannerblock3_fourblock h2{    line-height: 45px;font-size: 30px;}
.landingpage5_lstblock_subwrapper{padding:20px;padding-bottom:0;text-align:center}
.landingpage5_bannerblock3_secondblock_leftsection p br{display: none;}
.landingpage5_bannerblock3_secondblock_rightsection p br{display: none;}
.landingpage5_lstblock_subwrapper .abimage{position:inherit;margin:0 auto}
.landingpage5_lstblock .bluebgtitle{margin:0 auto;margin-bottom:10px}
.landing_page5_bannerblock .lpage_banner_sub_wrapper{width:100%}
.landing_page5_bannerblock .lpage_banner_textwrapper{width:100%;text-align:center}
.landing_page5_bannerblock .lpage_arrow_wrapper{background:#01befd;border-radius:10px;padding: 16px 60px 13px 39px;}
.landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size:28px;line-height:39px;text-align:center}
.landing_page5_bannerblock .lpage_banner_textwrapper1{background-color:#f5f5f5;padding:20px 40px;width:auto;text-align:center;color:#000;margin-bottom:10px;align-items:center;border-radius:10px}
.landingpage5_bannerblock3_secondblock_leftsection p{padding-left:0;font-size:30px}
.landingpage5_bannerblock3_secondblock_rightsection p{padding-left:0;font-size:30px}
.landing_page5_bannerblock{background:none;background:#fefdec;background:linear-gradient(90deg,rgba(254,253,236,1) 0%,rgba(247,254,254,1) 25%,rgba(251,254,242,1) 50%,rgba(255,255,255,1) 75%,rgba(252,254,242,1) 100%)}
.landing_page5_bannerblock .landing-banner-right{margin-top:0;width:100%;margin-left:0}
.landingpage5_bannerblock2_right_subsection{margin: 0 auto;    margin-bottom: 25px;  margin-top: 25px;}
.landingpage5_bannerblock2_right_subsection label{font-size:34px;}
.landingpage5_bannerblock2_right_section h2{font-size:43px;font-weight:900;line-height:54px}
.landingpage5_bannerblock2_right_section h3{color:#0057a1;font-size:34px}
.landingpage5_bannerblock2 .landing-col-container2{padding-left:0}
.landingpage5_bannerblock2{background-image:none;padding:0 10px;padding-top:30px;background:#fefdec;background:linear-gradient(90deg,rgba(254,253,236,1) 0%,rgba(247,254,254,1) 25%,rgba(251,254,242,1) 50%,rgba(255,255,255,1) 75%,rgba(252,254,242,1) 100%);margin-top: -40px;}
.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:36px}
.landingpage5_bannerblock2_right_section p{font-size:23px}
.landingpage5_bannerblock3_firstblock{font-size:38px}
.landingpage5_bannerblock3_secondblock{border:1px solid #f5f5f5;border-radius:10px;display:flex;justify-content:space-between;flex-wrap:wrap;padding:10px}
.landingpage5_bannerblock3_secondblock_leftsection{width:100%;margin-bottom:10px;background-image:none;text-align:center;background:#fefdec;background:linear-gradient(90deg,rgba(254,253,236,1) 0%,rgba(247,254,254,1) 25%,rgba(251,254,242,1) 50%,rgba(255,255,255,1) 75%,rgba(252,254,242,1) 100%)}
.landingpage5_bannerblock3_secondblock_rightsection{width:100%;background-image:none;text-align:center;background:#fefdec;background:linear-gradient(90deg,rgba(254,253,236,1) 0%,rgba(247,254,254,1) 25%,rgba(251,254,242,1) 50%,rgba(255,255,255,1) 75%,rgba(252,254,242,1) 100%)}
.landingpage5_bannerblock3_thirdblock ul{column-count:4}
.landingpage5_bannerblock2_right_section h2 br{display: none;}
.landingpage5_bannerblock2_right_section h3 br{display: none;}
.landingpage5_block12_subwrapper{background-position:right bottom;padding:20PX;background:#0e3987}
.pr{padding-right:0}
.landingpage5_bannerblock7{background-color: #fbfaed;background-image: none;padding: 0 10px;}
.landingpage5_bannerblock7 p {  padding-left: 0;font-size: 24px;}
.landingpage5_bannerblock7 h3 { padding-left: 0;font-size: 40px;margin: 10px 0;}
.landingpage5_bannerblock7 h2 { padding-left: 0;font-size: 50px;line-height: 59px;}
.landingpage5_bannerblock6 h2{font-size: 38px;line-height: 46px;}
.landingpage5_bannerblock9{background-image: none;margin-bottom: 38px;padding: 0 10px;}
.landingpage5_bannerblock9_mainwrapper_secondpart{    padding-left: 0;margin: 0;}
.landingpage5_bannerblock9_mainwrapper_firstpart{width: 100%;}
.landingpage5_bannerblock9_mainwrapper_firstpart p{font-size: 22px;}
.landingpage5_bannerblock9_mainwrapper_firstpart h3{font-size: 35px; line-height: 41px;}
.landingpage5_bannerblock9_mainwrapper_firstpart h2{font-size: 50px;}
.landingpage5_bannerblock9_mainwrapper_secondpart p{    font-size: 22px;}
.landingpage5_bannerblock9_mainwrapper_secondpart h2{font-size: 40px;}
.landingpage5_bannerblock9_subwrapper1{background: none;    padding: 10px;}
.landingpage5_bannerblock9_subwrapper2 { background-position: left bottom,left bottom -88px; background-size: 100% auto; padding-bottom: 70%;}
.landingpage5_bannerblock8_sub_mainwrapper {flex-direction: column;}
.landingpage5_bannerblock8_step1 {width: 100%;}
.landingpage5_bannerblock8_step2 {width: 100%;}
.landingpage5_bannerblock8_step3 {width: 100%;}
.landingpage5_bannerblock8_step4{width: 100%;}
.landingpage5_bannerblock8_step5{width: 100%;} 
.landingpage5_bannerblock8_step6{width: 100%;} 
.landingpage5_bannerblock8_mainwrapper p{font-size: 24px;}
.landingpage5_bannerblock8_mainwrapper h3{font-size: 34px;line-height: 42px;}
.landingpage5_bannerblock8_mainwrapper .bluebgtitle{    bottom: -45px;}
.landingpage5_bannerblock8_mainwrapper h2 {font-size: 40px;line-height: 48px;}
.landingpage5_bannerblock8_mainwrapper .bluebgtitle { position: inherit; left: 0; transform: inherit;  bottom: 0; display: block;  width: 90%; margin: 0 auto; margin-bottom: 15px; margin-top: 3%; text-align: center;}
.landingpage5_lstblock_subwrapper .abimage{
    width: auto;
}
.l5_videowrapper {
    margin-bottom: 32px;
    width: auto;
}
.l5_videowrapper .l5_vdo_txt_wrp{width: auto;border-radius: 15px 15px;margin: 0 auto 25px;}

.landingpage5_lstblock_subwrapper .abimage{ width: auto;}
.landing-col-container4 h2 br{display: none;}
.landing_page5_bannerblock .lpage_banner_sub_titlewrapper{    width: 100%; margin-top: -15px;}
}
@media only screen and (max-width: 1064px) {
.landingpage5_bannerblock3_thirdblock ul{column-count:3}
.landingpage5_bannerblock3_thirdblock h2{text-align:center;font-size:41px;font-weight:600;line-height:43px}
.landingpage5_bannerblock3_fourblock{position: inherit; left: 0; transform: inherit; bottom: 0; display: block; width: 90%; margin: 0 auto; margin-bottom: 15px; margin-top: -3%; text-align: center;position: relative;}
.landingpage5_bannerblock8_mainwrapper {    padding: 30px 30px 30px 30px;}
.landingpage5_bannerblock2_right_subsection label {
    font-size: 28px;
}
}
@media only screen and (max-width: 946px){

.landingpage5_bannerblock8_mainwrapper {padding: 20px 10px 10px 10px;}
}

@media only screen and (max-width: 900px) {
.landingpage5_bannerblock2_right_subsection label{font-size:25px;}
.landingpage5_bannerblock3_thirdblock h2{text-align:center;font-size:37px;font-weight:600}
.landingpage5_bannerblock3_firstblock p{    line-height: 43px;}
.landingpage5_bannerblock2_right_section h2 {    line-height: 48px;}
.landingpage5_bannerblock2_right_section h3 {line-height: 36px;}
.landingpage5_bannerblock2_right_section p {    line-height: 28px;}

.landingpage5_bannerblock5_firstblock p{ font-size: 18px;     padding: 30px 70px 52px 70px;}

.landingpage5_bannerblock5_firstblock h3{ font-size: 38px; line-height: 40px;}



}
@media only screen and (max-width: 810px) {
.landingpage5_bannerblock3_thirdblock ul{column-count:2}
.landingpage5_bannerblock2_right_subsection{padding:25px 12px}
.landingpage5_bannerblock6_sub_firstblock{flex-direction: column;}
.landingpage5_bannerblock6_sub_firstblock img{width: auto; padding: 0;}
.landingpage5_bannerblock6 h2{font-size: 35px; line-height: 45px;}
.landingpage5_bannerblock6_firstblock {padding: 10px;}
.landingpage5_bannerblock6_mainwrapper {padding: 20px 20px;}

}
@media only screen and (max-width: 767px) {
.landingpage5_bannerblock3_secondblock_leftsection p,.landingpage5_bannerblock3_secondblock_rightsection p{padding:20px 0}
.landingpage5_bannerblock3_secondblock_leftsection span,.landingpage5_bannerblock3_secondblock_rightsection span{display:block;margin-bottom:10px}
.landingpage5_bannerblock8_mainwrapper p{font-size: 20px;}
.landingpage5_bannerblock8_mainwrapper h3 { font-size: 30px;  line-height: 37px;}

}
@media only screen and (max-width: 700px){
.landingpage5_bannerblock2_right_subsection { padding: 16px 10px; text-align: center;}
}
@media only screen and (max-width: 583px) {

.landingpage5_bannerblock3_firstblock{font-size:34px}
.landingpage5_bannerblock3_secondblock_leftsection p{padding-left:0;font-size:27px}
.landingpage5_bannerblock3_secondblock_rightsection p{padding-left:0;font-size:27px}
.landingpage5_bannerblock3_thirdblock{padding:24px 20px}
.landingpage5_bannerblock6 h2{font-size: 30px; line-height: 41px;}
.l5_videowrapper .l5_vdo_txt_wrp h2 {
    font-size: 36px;
    line-height: 42px;
    
}
}
@media only screen and (max-width: 490px){
.landingpage5_bannerblock3_thirdblock h2{line-height: 40px;}
.landingpage5_bannerblock3_fourblock{left: 0; transform: inherit; bottom: 0; display: block; width: 90%; margin: 0 auto; margin-bottom: 15px; margin-top: 6%; text-align: center; position: relative}
.landingpage5_bannerblock8_mainwrapper p{font-size: 18px;}
.landingpage5_bannerblock8_mainwrapper h3 { font-size: 26px;  line-height: 33px;}
.landingpage5_bannerblock8_mainwrapper h2{    font-size: 35px;line-height: 43px;}
.landingpage5_bannerblock9_mainwrapper_firstpart p { font-size: 20px;}
.landingpage5_bannerblock9_mainwrapper_firstpart h2 {font-size: 35px;}
.landingpage5_bannerblock9_mainwrapper_firstpart h3 { font-size: 27px; line-height: 37px;}
.landingpage5_bannerblock9_mainwrapper_secondpart h2 { font-size: 27px;}
.landingpage5_bannerblock9_mainwrapper_secondpart p { font-size: 20px;}
.landingpage5_bannerblock7 p { padding-left: 0;font-size: 20px;}
.landingpage5_bannerblock7 h3 { padding-left: 0; font-size: 27px;}
.landingpage5_bannerblock7 h2 { padding-left: 0; font-size: 35px; line-height: 47px;}
.landingpage5_bannerblock3_fourblock h2 {
    line-height: 34px;
    font-size: 25px;
    
}
}


@media only screen and (max-width: 480px) {
    .landingpage5_bannerblock3_thirdblock ul{column-count:1;align-items:center}
.landingpage5textwrapper h1{font-size:28px;line-height:34px}
.bluebgtitle p{font-size:24px;line-height:31px}
.demo5Con .bluebgtitle p{font-size:24px;line-height:31px;}
.landingpage5_bannerblock4 h2 { font-size: 34px; line-height: 40px;}
.landingpage5_bannerblock4 p { font-size: 25px;}
.landingpage5_bannerblock8_step1{display: block;}
.landingpage5_bannerblock8_step2{display: block;}
.landingpage5_bannerblock8_step3{display: block;}
.landingpage5_bannerblock8_step4{display: block;}
.landingpage5_bannerblock8_step5{display: block;}
.landingpage5_bannerblock8_step6{display: block;}
.landingpage5_bannerblock8_step1_img::before{display: none;}
.landingpage5_bannerblock8_step1_img::after{display: none;}
.landingpage5_bannerblock8_step2_img::before{display: none;}
.landingpage5_bannerblock8_step2_img::after{display: none;}
.landingpage5_bannerblock8_step3_img::before{display: none;}
.landingpage5_bannerblock8_step3_img::after{display: none;}
.landingpage5_bannerblock8_step4_img::before{display: none;}
.landingpage5_bannerblock8_step4_img::after{display: none;}
.landingpage5_bannerblock8_step5_img::before{display: none;}
.landingpage5_bannerblock8_step5_img::after{display: none;}
.landingpage5_bannerblock8_step6_img::before{display: none;}
.landingpage5_bannerblock8_step6_img::after{display: none;}
.landingpage5_bannerblock8_step1_img{margin-left: 0px;}
.landingpage5_bannerblock8_step2_img{margin-left: 0px;}
.landingpage5_bannerblock8_step3_img{margin-left: 0px;}
.landingpage5_bannerblock8_step4_img{margin-left: 0px;}
.landingpage5_bannerblock8_step5_img{margin-left: 0px;}
.landingpage5_bannerblock8_step6_img{margin-left: 0px;}
}
@media only screen and (max-width: 410px) {
.landingpage5_bannerblock3_fourblock h2{font-size:24px;font-weight:700;padding:15px 25px;text-align:center}
.landingpage5_bannerblock3_thirdblock li{position:relative;font-size:18px;font-weight:600;padding:0 0 11px 33px}
.landingpage5_bannerblock3_thirdblock h2{text-align:center;font-size:28px;font-weight:600}
.landingpage5_bannerblock3_firstblock{font-size:26px;background:#0be8fe;background:linear-gradient(180deg,rgba(11,232,254,1) 0%,rgba(70,180,254,1) 100%);width:-moz-fit-content;width:fit-content;padding:25px 0;font-weight:600;text-align:center;border-radius:10px}

.landingpage5_bannerblock2_right_section p{font-size:16px}
.landingpage5_bannerblock2_right_section h3 span{color:#0057a1;font-size:29px}
.landingpage5_bannerblock2_right_section h2{font-size:32px;font-weight:900;line-height: 35px;}
.landing_page5_bannerblock .lpage_arrow_wrapper h2{font-size:19px;line-height:24px;text-align:center}
.landing_page5_bannerblock .lpage_banner_textwrapper1 p{font-size:28px}
.landingpage5_bannerblock6 h2 {font-size: 25px;line-height: 36px;}
.landing_page5_bannerblock .lpage_banner_sub_wrapper h2 span{font-size: 40px;}
}