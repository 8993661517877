
.program h3{
    font-size: 26px;
    /* width: 40%; */
    /* background: #0091ff; */
    color: var(--orange);
    /* padding: 10px; */
    margin: 20px 0;
}
.program span{
    color: black;
}
.program_details{
    /* background: #b4acac; */
    min-height: 250px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    /* align-items: center; */
}
.programButtonClass{
    padding: 10px 25px;
    font-size: 22px;
    margin: 10px 15px;
    background:var(--blue2);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
}
.programButtonClass a{
    text-decoration: none;
    color: #fff;
    
}
.titel_wrper{
    position: relative;
    overflow: hidden;
    transition: 0.8s;
    /* cursor: pointer; */
    background: #fff;
    border-radius: 8px;
    display: block;
    box-shadow: 4px 4px 25px hsl(0deg 3% 42% / 15%);
    padding: 15px;
    flex-basis:100% !important;
    max-width: 100% !important;
    border-radius: 8px;
    
}
.titel_file{
    font-size: 25px;
    text-align: center;
}
.meterial_button_wrapper{
    display: flex;
    justify-content: center;
}
.meteralblockk{
    /* margin: 18px auto; */
    /* box-shadow: 0 0 100px rgb(150 151 142 / 76%); */
    width: 100px;
    height: 100px;
}
.box_wraper{
    display: flex;
    /* flex: 1 0 31.33333333%; */
    margin: 0.5%;
    width: 32%;
}
.titel_file span{
    color: var(--orange);
}
 h1.MuiTypography-root{
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    color: #fff;
    background: #0569ad;
}
.modaldatashow_wrapper{
    text-align: center;
}
.Preview_material.css-0{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* background-color: background.paper; */
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 32px;
    text-align: center;
    min-width: 600px;
}
.icon_wrper{
    width: 160px;
    height: 160px;
    border-radius: 100px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    animation: color-change 1s infinite;
    position: relative;
    z-index: 1;
    margin: 0 auto;
}
.filtersearch .MuiPaper-root{
    width: 600px;
}

.icon_wrper img{
    width: 160px;
    height: 160px;
    border-radius: 100px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    animation: color-change 1s infinite;
    position: relative;
    z-index: 1;
    margin: 0 auto;
}
 