.hm_details{
    width: 100%;
   
}
.hm_details tr:last-child{
    border-bottom: 0.9px solid black;
}
.hm_details th button{
    border: none;
    background: none;
}
.hm_details tr td{
    padding: 20px;
}
.hm_details ul{
    list-style: none;
}
.hm_details .hm_title{
    font-weight: bold;
}
.hm_details input{
    margin-top: 15px;
}

.hm_details label{
    margin-left: 10px;
}
.sort_icon{
    transform: rotate(90deg);
    position: relative;
    top: 5px;
}

.sort_icon1{
   
    position: relative;
    top: 6px;
}

.sort_icon2{
    transform: rotate(180deg);
    position: relative;
    top: 6px;
}

@media screen and (max-width:1199px) {

    .hm_details th{
        display: none;
    }
    .hm_details td::before{
        content: attr(title);
        font-size: 20px;
        
    }

  
    
}

@media screen and (max-width:499px) {

   .srchfld_wrp{
    margin-left: auto;
   }

   .listing_heading h1{
    text-align: center;
   }
   .hm_details td::before{
    content: attr(title);
    font-size: 20px;
    margin-bottom: 15px;
    
}
    
}