.loginBody{   width: 100%; min-height: 100vh; padding-bottom: 15px; display: flex; align-items: center; justify-content: center;  flex-direction: column; position: relative; 
  /* background: url('https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/login_bg.webp'); background-size: cover;   */
  overflow-x: hidden;
  background-color: #0C272F;
background-image: url("https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/login_mainBG.svg");
}
 
.loginBox{ background-color: #fff;   border-radius: 20px; box-shadow: -2px 0px 15px 1px rgb(81,81,81,50%); width:700px; 
      overflow: hidden; display: flex; align-items: center; justify-content: center; position: relative;  box-shadow: 0 0 50px 15px #186a83;
     }
 

.logo{   max-width: 80%;  margin-bottom: 30px;   ;
}



 .loginBox::before{ content: '';  width:100%; height: 100%; position: absolute; top: -50%; left: -50%;   background: linear-gradient(0deg, transparent, #f79a08, #f79a08);
  transform-origin: bottom right;   animation: animate 6s linear infinite;}

 .loginBox::after{ content: '';  width:100%;   height: 100%; position: absolute; top: -50%; left: -50%;   background: linear-gradient(0deg, transparent, #f79a08, #f79a08);
  transform-origin: bottom right;  animation: animate 6s linear infinite;  animation-delay: 3s;}
 
 @keyframes animate {
  
    0%{

        transform: rotate(0deg);
    }
    100%{

        transform: rotate(360deg);
    }
    
 }   

 .loginBox_body{   inset: 4; z-index: 10; background: #fff; border-radius:18px; padding: 30px; overflow: hidden; margin:5px; width:100%; min-height: 400px; display: flex;  justify-content: center; flex-direction: column; }

 .loginBox_body h1 {
    color: #0C272F;
 
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    margin: 0;
    padding:0 0 15px 0;
    text-align: center;
}


.loginBox_body .MuiFormControl-root {
    background: #fff;
    border-radius: 4px; width: 100%;
  }
  
  .loginBox_body .MuiFormControl-root:nth-child(even) {
    margin-top: 36px; margin-bottom: 10px;
  }
  
  .loginBox_body .MuiFormControl-root label {
    color: #10273d;
  }
  

  .loginBox_body .formGroup {
    background: #fff;
    border-radius: 4px;     margin-top: 16px;
  }
  
  .loginBox_body .formGroup:nth-child(even) {
    margin-top: 36px;margin-bottom: 10px;
  }
  
  .loginBox_body .formGroup label {
    color: #10273d;
  }
  

  .loginBox_body .formGroup .MuiFormHelperText-root{    background: #d32f2f;
    color: #fff!important;
    margin: -2px 0 0 0;
    padding: 4px 4px 0 4px;
    border-radius: 0 0 2px 2px;}




  .loginBox_body svg {
    color: #0282bc;
  }
  
  .loginBox_body p.Mui-error {
    background: #d32f2f;
    color: #fff!important;
    margin: -2px 0 0 0;
    padding: 4px 4px 0 4px;
    border-radius: 0 0 2px 2px;
  }


  .loginBox_body .passBttn {
    background: #0cd1ff;
    background: linear-gradient(180deg,#0cd1ff,#01b2ff);
    font-size: 32px;
    color: #fff;
    font-weight: 600;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
      0px 1px 5px 0px rgb(0 0 0 / 18%);
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    margin: 12px 0 0 0; box-shadow: none!important;
  }

  .loginBox_body input:-internal-autofill-selected {
    background-color: -internal-light-dark(
      rgb(255, 255, 255),
      rgb(255, 255, 255)
    ) !important;
    -webkit-box-shadow: 0 0 0 35px #ffffff inset !important;
    position: relative;
  }

  .loginBox_body .forgot_password {
    color: #484747;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
}

.loginBox_body .Back_to_Login {
  color: #484747;
  font-size: 20px;
  font-weight: 500;     display: inline-block;
  text-decoration: none; margin: 18px 0 0 0;
}

.loginerror{ margin-bottom: 20px;}
.resatbuttonwrapper { display: flex; flex-wrap: wrap; justify-content: space-between;}
.resatbuttonwrapper button{background: #0cd1ff;
  background: linear-gradient(180deg,#0cd1ff,#01b2ff);
  font-size: 32px;
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 18%);
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  margin: 16px 0 0 0; box-shadow: none!important; width: 49%;}


@media only screen and (max-width: 900px) {
.loginBox{ width: 90%;}

}  

@media only screen and (max-width: 600px) {
  .loginBox_body h1{ font-size: 30px; line-height: 32px;}

  .loginBox_body{ min-height: 280px; padding: 15px;}
}

@media only screen and (max-width: 400px) {
  .resatbuttonwrapper{ display: block;}
  .resatbuttonwrapper button{ width: 100%;}
}

