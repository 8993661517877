 
.dashboard_con .dashboard_block1{background: #fff;
    border-radius: 8px; display: flex;
    box-shadow: 4px 4px 25px hsla(0,1.9%,42%,0.15);   position: relative; margin-bottom: 1%; flex-wrap: wrap; align-items: center; justify-content: space-between;}
.dashboardCommon{padding: 25px; }
.dashboard_con2 .active{background: #daeaf3;}
.dashboard_block1 h2{ margin: 0; padding: 0; font-size: 26px; font-weight: 400;  color: var(--orange);}   
.dashboard_block1 h2 span{color: var(--black);}
.dashboard_block1 h3{ margin: 0; padding: 0 40px 0 0; color: var(--gray2); font-size: 18px; font-weight: 400; border-bottom: solid 2px var(--blue2);   }
.dashboard_block1 h3 span{ display: inline-block; margin-right:50px; padding: 10px 0 10px 10px; position: relative; background: var(--blue2);  height: 100%; color: #fff;}
.dashboard_block1 h3 span::after {
    content: '';
    display: block;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    width: 50px;
    height: 100%;
    background: var(--blue2); 
    right: -49px;
    bottom: 0;
    position: absolute;
}

.dashboard_Icon_block{background: #fff;
    border-radius: 8px; display: block;
    box-shadow: 4px 4px 25px hsla(0,1.9%,42%,0.15);   position: relative;}
 
.dashboard_con2{ display: flex; flex-wrap: wrap; justify-content: center;
    align-items: center; margin: 0 -0.5%; margin-bottom: 15px;}

.dashboard_Icon_block{ display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;     flex: 1 1 20%;     margin: 0.5%;
    max-width: 48%; position: relative; overflow: hidden; transition: 0.8s;  position: relative; }

    /* .dashboard_Icon_block:hover{ background: #ccc;}    */

.dashboard_Icon_block:before{ content: ''; display: block; width: 100%;   height: 0%; top: -1px; border-radius: 0 0 50% 50%; background: #daeaf3;  position: absolute; left: 0; top: 0; transition: 0.8s;}   
 
.dashboard_Icon_block:hover.dashboard_Icon_block:before{height: 180%;}

/* .dashboard_Icon_block:hover::before{ height: 180%;} */

.dashboard_Icon_block label{ display: block; margin: 0; padding: 0; font-weight: normal; font-size: 16px; color: var(--orange);}
.dashboard_Icon_block strong{ display: block; margin: 0; padding:0; font-weight: 500; font-size: 22px; color: #000;}
.dashboard_Icon_block p{ margin: 0; padding: 0; font-size: 12px; color: var(--blue2); display: flex; align-items: center;}
.dashboard_Icon_block p span{ display: inline-block; padding-right: 10px; color: var(--gray4);}
.dashboard_Icon_block p.decrease{color: var(--red);}

.dashboard_Icon_block a {
    background: var(--blue2);
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    padding: 5px 10px;
    display: block;
    text-align: center;
    margin-top: 5px;
    border-radius: 4px;
    width: 100px;
}
.dashboard_Icon_block_text{ position: relative; z-index: 1;}

.dashboard_Icon_box{ width: 80px; height: 80px;  border-radius: 100px; padding: 10px; display: flex; flex-wrap: wrap; justify-content: center; align-items: center;   
    animation: color-change 1s infinite; position: relative; z-index: 1;}


   

 @keyframes color-change {
        0%   {background-color:#fff8e3;} 
        50%  {background-color: #fbeec3;}
        100% {background-color:#fff8e3;} 
  }
 
.dashboard_Icon_box svg{  display: block; width: 40px; height: 40px; color: #b5914f;}

.dashboard_block3{background: #fff;
    border-radius: 8px; display: block;
    box-shadow: 4px 4px 25px hsla(0,1.9%,42%,0.15);   position: relative; height:  calc(100% - 25px);}


 .dashboard_con3{margin-bottom: 25px; display: flex; flex-wrap: wrap; justify-content: space-between; }  

  .dashboard_con3 .MuiTableContainer-root{ box-shadow: none!important; border-radius: 0px;}

  .dashboard_con3 .MuiTableContainer-root th{ border: solid 1px #ccc!important; padding: 8px; text-align: left; font-size: 16px;} 
  .dashboard_con3 .MuiTableContainer-root td{ border: solid 1px #ccc!important;  padding: 8px; text-align: left; font-size: 16px;}  

  .dashboard_con3 h2{ margin: 0 0 15px 0;   
    padding:0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);}

  .dashboard_con3 .dashboard_con3_sub{background: #fff;
    border-radius: 8px; 
    box-shadow: 4px 4px 25px hsla(0,1.9%,42%,0.15); padding: 25px; width: 39.5%; }  


  .dashboard_con3 .dashboard_con3_sub canvas{ width: 100%!important; height: auto!important;}

  .dashboard_con3 .dashboard_con3_sub .canvasjs-chart-container a{ display: none;}

.dashboard_con3 .dashboard_con3_sub:nth-of-type(2){ width: 60%;}  


 .dashboard_con3_listview{background: #fff;
    border-radius: 8px; 
    box-shadow: 4px 4px 25px hsla(0,1.9%,42%,0.15); padding: 25px;   }  

.dashboard_con3 .divul{ margin: 0; padding: 0;     padding-bottom: 60px; }

.dashboard_con3  .divli{ list-style: none; border-bottom: solid 1px #ccc; ; padding: 8px; font-size: 16px; text-align: left; display: flex; flex-wrap: wrap; justify-content: space-between;}

.dashboard_con3  .divli span{ font-weight: 500;     color: rgba(0, 0, 0, 0.87); text-align: left; width: 40%;}
.dashboard_con3  .divli strong{ font-weight: normal;     color: rgba(0, 0, 0, 0.87); text-align: left; width: 59%;}

.dashboard_con3  .control-dots{ display: none;}

.dashboard_con3  .carousel .carousel-status{ position: absolute; top: inherit; right: inherit; width: 100%; left: 0; bottom: 0; text-align: center; color: #000; font-size: 12px; text-shadow: none!important;}
.carousel .thumbs-wrapper{ display: none!important;}

.CarouselView{ position: relative;}
.CarouselView .carousel.carousel-slider{ position: inherit;}
.CarouselView .carousel .control-dots{ display: none;}

.CarouselView  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000!important;
}
.CarouselView  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000!important;
}

.CarouselView  .carousel.carousel-slider .control-arrow{ top: inherit; bottom: 5px;     width: 30px;
    height: 30px;   padding: 0; border-radius: 100px!important;}
.CarouselView .control-prev { left: 5px!important;}
.CarouselView  .control-next{ right: 5px!important;}


.card_element user-card{
    width: 100%;
    display: flex;
  
    justify-content: space-between;
    align-items: center;
    
    margin: 0.5%;
   
    overflow: hidden;
    transition: 0.8s;
    
    position: relative;
}
.card_element{
    flex: 1 1 20%;
    margin: 0.5%;
    max-width: 48%;
    position: relative;
    overflow: hidden;
    transition: 0.8s;
    /* cursor: pointer; */
    position: relative;
}
.card_element .cardTitleHead {
    display: flex;
}



.card_element button {
    cursor: pointer;
}

.card_element .dashboard_Icon_block_text button{
    background: var(--blue2);
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    display: block;
    text-align: center;
    margin-top: 5px;
    border-radius: 4px;
    width: 100px;
    padding: 9px 10px;
    border: none;
  
}
.card_element .fa-question{

    margin-left: 8px;
    border: 1px solid #f87f07;
    font-size: 13px;
    border-radius: 50% 50% 50% 50%;
}

.card_element .negativeClass {
    color: #ff0000;
}

.card_element .positiveClass {
    color: #008300;
}
.noDataAvailableGrid img {
    max-width: 30%;
    margin-top: 110px;
}

.gird_chart_tooltip{
    position:relative;top:5px;font-size:22px;border: 1px solid black;border-radius: 50%;margin-left: 10px;
}

.daterangepicker_view_btn button{
    background: var(--blue2);
    border: none;
    border-radius: 4px;
    color: #fff;
    display: block;
    font-size: 16px;
    margin-top: 5px;
    padding: 9px 10px;
    text-align: center;
    text-decoration: none;
    width: 100px;
    margin-left: 20px;
    margin-bottom: 4px;
}
.daterangepicker_view_btn button:hover {
background: var(--blue2);

}

.daterangepicker_one{
    display: flex;
    margin-bottom: 9px;
}

span.dash_brd_date {
    margin-right: 20px;
    margin-top: 14px;
}

.fa-solid, .fas {
    font-weight: 700;
    padding: 3px;
}
.iconClass{
    font-size: xx-large;
}
.dashboard_con3_listview .css-pjjt8i-MuiToolbar-root { margin: 0; padding: 0; min-height: inherit;}
.dashboard_con3_listview .css-pjjt8i-MuiToolbar-root .MuiTypography-h6 {
    margin: 0 0 15px 0;
    padding: 0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
}

.dashboard_con4 { border-radius: 8px;
  padding: 25px; 
    margin: 0;
    margin-bottom: 15px; margin-top: -5px;
    box-shadow: 4px 4px 25px hsl(0deg 2% 42% / 15%);
  background: #fff; 
}

.dashboard_con4_wrapper{ display: flex; flex-wrap: wrap; justify-content: space-between; margin-top: -0.5%;}

.dashboard_con4 h2 {
    margin: 0 0 15px 0;
    padding: 0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);
}

.customlinksData_block{width:100%; border-radius: 8px;
    margin: 0.5% 0; 
    overflow: hidden;  background-color: #f3f3f3;   
    transition: 0.8s; 
    position: relative; display: block;}

 .customlinksData_con{ display: flex; flex-wrap: wrap; justify-content: space-between;     padding: 15px;}   
 .customlinksData_IMG{ width:400px; height: 200px; overflow: hidden; display: flex; flex-wrap: wrap; justify-content: center; align-items: center;  padding: 5px;    background-color: #fff;}
 .customlinksData_IMG img{ width: 100%;}
 .customlinksData_text {
    width: calc(100% - 420px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

 

.customlinksData_text p{ font-size: 14px; color: #333; line-height: 22px;  }

  .customlinksData_text p span{ overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;}
    .customlinksData_text .text_CustmbuttonWrp{
        position: relative;
    }
    .customlinksData_text .text_CustmbuttonWrp p{
        padding-right: 50px;
    }
    .customlinksData_text .text_CustmbuttonWrp button{ margin: 0; padding: 0; min-width: inherit;    color: var(--orange);position: absolute;right: 0;top: 0;}

 .customlinksData_text label{    margin:0;
    padding:0 0 5px 0; display: block;
    font-size: 18px;
    color: #000; font-weight: 500; position: relative; }

 .customlinksData_text label::after{ content: ''; width: 100%; height: 2px;  background-color: #cbcbcb; position: absolute; left: 0; top: 50%;  transform: translatey(-50%);  }  

 .customlinksData_text label span{ display: inline-block; padding-right: 5px; background-color: #f3f3f3; position: relative;  z-index: 9;}
 
.customlinksData_text .linktext {
    margin: 0;
    display: flex;
    width: calc(100% - 75px); 
    padding: 0;
    font-size: 16px;
    color: var(--blue2);
    flex-wrap: wrap;
    align-items: center;
}
  .customlinksData_text .linktext svg{  color: var(--blue2); margin-right: 10px;}     

 .customlinksData_text .customlinksData_text_icon{ display: flex; flex-wrap: wrap;   align-items: center;  justify-content: space-between; width: 90px;}      


 .customlinksData_text .customlinksData_text_icon span{ display: flex; cursor: pointer; justify-content: center; align-items: center; margin:2px;  background-color: var(--orange); width: 38px; height: 38px; border-radius: 100px; padding: 5px;}
 
 .customlinksData_text .customlinksData_text_icon span svg{ color: #fff; width: 25px; height: 25px;}
 .errortextcss{ display: block; color: #ff0000; text-align: center; width: 100%;}

 .customlinksData_text_link{ background: #fff; width:calc(100% - 260px); border-radius:  4px ;     display: flex;  flex-wrap: wrap;}
 .customlinksData_text_wrapper{ display: flex; flex-wrap: wrap; justify-content: space-between; margin-top: 15px;     word-break: break-all;}

 .customlinksData_text_wrapper .customlinksData_select{ width: 150px;}
 .customlinksData_text_wrapper .customlinksData_select .MuiOutlinedInput-root{ width: 100%;     background: #fff;}

 .customlinksData_text_link span{ display: block;  background-color: var(--orange); width: 70px; height: 50px; border-radius: 4px 0 0 4px; clip-path: polygon(0 0, 0% 100%, 100% 0);}
 .customlinksData_text_link span svg{ color: #fff;     margin: 6px 0 0 8px;}
 
 .customlinksData_text .customlinksData_text_icon span.MuiBadge-badge{ background-color: #111; width: auto; min-height: 20px; height: 20px; padding: 2px;}


 .landingmodalCon .h1textcon{ margin: 0; padding: 0 0 5px 0; 
    font-size: 22px;
    color: var(--blue2);
    font-weight: 500; 
}


/* <------------------HM Banner CSS Starts---------------> */

/* .dsbrd_user_banner {padding: 35px 50px 15px 45px;background: linear-gradient(180deg, rgba(255,255,255,1) 82%, rgba(253,253,253,1) 90%);}
.dsbrd_user span {font-size: 22px;}
.dsbrd_user span:nth-of-type(1) {color: #009ef5;font-weight: 600;}
.dsbrd_user span:nth-of-type(2) {font-weight: 400;margin-left: 7px;}
.dsbrd_user_details .dsbrd_user{padding: 7px 3px;} */

.hm_test_details {display: flex;}
.hm_test_details h2:not(:first-child) {margin-left: 40px;}

/* <------------------HM Banner CSS Ends---------------> */

/* <------------------HM Banner Responsive Starts---------------> */
/* <------------------HM Banner Responsive Ends---------------> */







 @media only screen and (max-width: 1300.98px) { 
    .dashboard_Icon_block{flex: 1 1 32%;}
 }

 @media only screen and (max-width: 1200.98px) {
    .customlinksData_text_link{ width: 100%; margin-bottom: 10px;}
 }

@media only screen and (max-width: 1199.98px) {
.dashboardCommon{ padding: 15px;}
 .dashboard_con .dashboard_block1{ flex-direction: column;}
 .dashboard_block1 h2{ margin-bottom: 10px;}
 .dashboard_con3{ display: block;}
 .dashboard_con3 .dashboard_con3_sub:nth-of-type(2){ width: auto; margin-top: 15px;}
 .dashboard_con3 .dashboard_con3_sub{ width: auto;}

 
 .dashboard_con3_listview  table,  .dashboard_con3_listview tbody,   .dashboard_con3_listview td, .dashboard_con3_listview tr {  display: block;  width: 100%; min-width: inherit!important;
 }
 
 .dashboard_con3_listview tr { margin: 10px 0;  border: solid 1px #ccc; }
 
 .dashboard_con3_listview tr:first-of-type{ margin-top: 0px;}
 
 .dashboard_con3_listview thead{ display: none;}
 
 .dashboard_con3_listview td{ position: relative;  padding-left: 50%!important; }
 
 .dashboard_con3_listview td:before {  content: attr(title); position: absolute; left: 0; padding-left: 10px; font-weight: bold;}
.dashboard_con3_listview td .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{ padding: 0px!important;}
.dashboard_con3 .divul{ padding-bottom: 25px;}
}

@media only screen and (max-width:991.98px) {

    .customlinksData_text_link{  margin: 0;   width: calc(100% - 260px);}

  
    .dashboard_Icon_block {
        flex: 1 1 42%; max-width: inherit;
    }
    .customlinksData_con{ display: block; width: 100%;}
    .customlinksData_IMG{  margin: 0 auto; margin-bottom: 15px;}
    .customlinksData_text{ width: auto;}

    .customlinksData_text p{    overflow: inherit;   display: inherit;}
   
 }


@media only screen and (max-width: 640.98px) {
    .customlinksData_text_link{  margin: 0 0 10px 0;   width: 100%;}

    .customlinksData_text_wrapper .customlinksData_select {
        width: 150px;
        margin: 0 auto;
    }
    .customlinksData_text .customlinksData_text_icon {
        margin:10px auto; 
    }
    .customlinksData_text_wrapper{ display: block;}

.dashboard_block1 h3 span::after { display: none;}
.dashboard_block1 h3 span{ margin-right: 0; padding: 0; background: none;}
.dashboard_block1 h3 {  text-align: center; padding: 5px 15px;   background: var(--blue2); color: #fff; border: none;}
}

@media only screen and (max-width:540.98px) {
.dashboard_Icon_block {
    flex: inherit;
    width: 100%;
}
.customlinksData_text_wrapper{ display: block;}

.customlinksData_text_link{ width: auto;}
 

.customlinksData_IMG{  width: auto; height: auto;}
}

